import {Tabs} from 'antd';
import {useFetchSchoolsQuery} from 'api/apiSlice';
import EsoyITSupportNyssStats from 'components/esoy/EsoyITSupportNyssStats';
import EsoyImportFiles from 'components/esoy/EsoyImportFiles';
import EsoyTaskStats from 'components/esoy/EsoyTaskStats';
import SelectOptions from 'components/genericComponents/SelectOptions';
import React, {useEffect, useState} from 'react';
import {GRADESPANS} from 'utils/consts';

function EsoyITSupportPage() {
  const {data} = useFetchSchoolsQuery();
  const [region, setRegion] = useState('All');
  const [gradespan, setGradespan] = useState('All');
  const [filteredSchools, setFilteredSchools] = useState([]);

  useEffect(() => {
    if (data?.schools) {
      filterSchools();
    }
  }, [region, gradespan, data]);

  const filterSchools = () => {
    let tempFilteredSchools = [...data?.schools];
    if (region !== 'All') {
      tempFilteredSchools = tempFilteredSchools.filter(
        (school) => school.region && school.region.trim() === region
      );
    }
    if (gradespan !== 'All') {
      tempFilteredSchools = tempFilteredSchools.filter(
        (school) => school.gradespan === gradespan
      );
    }
    setFilteredSchools(tempFilteredSchools);
  };

  return (
    <div>
      <h1>E/SOY - IT Support</h1>
      <div>
        <div className="flex-row" style={{justifyContent: 'center'}}>
          <SelectOptions
            allowClear={false}
            onChange={setRegion}
            options={data?.regions}
            size="middle"
            value={region}
          />
          <SelectOptions
            allowClear={false}
            onChange={setGradespan}
            options={GRADESPANS}
            size="middle"
            value={gradespan}
          />
        </div>

        <Tabs
          defaultActiveKey="0"
          items={[
            {
              label: 'Task Completion Overview',
              key: 1,
              children: (
                <EsoyTaskStats
                  key={filteredSchools}
                  schools={filteredSchools}
                />
              ),
            },
            {
              label: 'Generate Import Files',
              key: 2,
              children: (
                <EsoyImportFiles
                  key={filteredSchools}
                  filteredSchools={filteredSchools}
                />
              ),
            },
            {
              label: 'NYSS Progress Overview',
              key: 3,
              children: (
                <EsoyITSupportNyssStats
                  region={region}
                  gradespan={gradespan}
                  key={filteredSchools}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default EsoyITSupportPage;
