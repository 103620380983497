import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  authState: null,
  accessToken: null,
};

export const googleAuthSlice = createSlice({
  initialState,
  name: 'googleAuth',
  reducers: {
    setAuthState: (state, action) => {
      state.authState = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

export const {setAuthState, setAccessToken} = googleAuthSlice.actions;
export default googleAuthSlice.reducer;
