import Auth from 'Auth';
import {useFetchPagesQuery} from 'api/cmsSlice';
import Catalog from 'components/genericComponents/Catalog';
import React from 'react';
import {useLocation} from 'react-router-dom';

const DataPortalOverviewPage = () => {
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const {data: pages, isLoading: pagesLoading} = useFetchPagesQuery(
    editorMode ? 'latest' : 'published'
  );

  const extraTags = [
    {
      color: 'red',
      id: 'draft',
      label: 'Draft',
      name: 'Draft',
      value: 'draft',
    },
    {
      color: 'orange',
      id: 'unpublished',
      label: 'Unpublished Changes',
      name: 'Unpublished Changes',
      value: 'unpublished',
    },
    {
      color: 'blue',
      id: 'search_context',
      label: 'Search Context',
      name: 'Search Context',
      value: 'search_context',
    },
    {
      color: 'green',
      id: 'spotlight',
      label: 'Spotlight',
      name: 'Spotlight',
      value: 'spotlight',
    },
  ];

  return (
    <div>
      <h1>Data Portal Overview</h1>
      <Catalog
        buttons={[
          {
            label: 'Create New Page',
            href: `/data_portal/new/editor`,
          },
        ]}
        editorPermission={Auth.permissions.access_to_data_portal_editor}
        extraTags={extraTags}
        loading={pagesLoading}
        pages={pages}
        path="data_portal"
      />
    </div>
  );
};

export default DataPortalOverviewPage;
