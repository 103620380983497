import {ExportOutlined} from '@ant-design/icons';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {setAccessToken, setAuthState} from 'store/googleAuthSlice';
import {tsToNy} from 'utils/helpers';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const DocViewer = ({docLink, widget = {}}) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.googleAuth.authState);
  const accessToken = localStorage.getItem('googleAccessToken');

  const [metadata, setMetadata] = useState(null);
  const [error, setError] = useState(null);
  const docId = docLink.split('/')[5];

  const setGoogleSource = (val) => {
    try {
      const url = new URL(val);
      const path = url.pathname.split('/');
      const type = path[1];
      const id = path[3];

      if (type === 'document') {
        return `https://docs.google.com/document/d/${id}/preview`;
      } else if (type === 'spreadsheets') {
        return `https://docs.google.com/spreadsheets/d/${id}/preview`;
      } else if (type === 'presentation') {
        return `https://docs.google.com/presentation/d/${id}/preview`;
      } else if (type === 'file') {
        return `https://drive.google.com/file/d/${id}/preview`;
      }
      return val;
    } catch (error) {
      console.error('Error parsing google url', error);
      return val;
    }
  };

  const fetchMetadata = useCallback(
    async (token) => {
      const fields =
        'name,owners(displayName),modifiedTime,lastModifyingUser(displayName)';
      try {
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files/${docId}?fields=${fields}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          handleAuth(handleCredentialResponse, dispatch, fetchMetadata);
          return;
        }

        const data = await response.json();
        setMetadata(data);
      } catch (err) {
        setError(err.message);
      }
    },
    [docId]
  );

  const handleTokenRequest = useCallback(
    (tokenClient) => {
      dispatch(setAuthState('authenticating'));
      const popupBlockedTimeout = setTimeout(() => {
        if (authState === 'authenticating') {
          dispatch(setAuthState('popup-blocked'));
        }
      }, 1000);
      try {
        tokenClient.requestAccessToken({
          prompt: 'consent',
          callback: (response) => {
            clearTimeout(popupBlockedTimeout);
            if (response.error === 'popup_closed_by_user') {
              dispatch(setAuthState('popup-closed'));
              setError('Popup closed by user');
            }
          },
        });
      } catch (e) {
        clearTimeout(popupBlockedTimeout);
        dispatch(setAuthState('popup-blocked'));
        setError('Popup blocked');
      }
    },
    [authState]
  );

  const handleCredentialResponse = useCallback(
    (response) => {
      if (!metadata && !error) {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
          callback: (tokenResponse) => {
            if (tokenResponse.access_token) {
              dispatch(setAccessToken(tokenResponse.access_token));
              localStorage.setItem(
                'googleAccessToken',
                tokenResponse.access_token
              );
              fetchMetadata(tokenResponse.access_token);
            } else {
              setError('Failed to get access token');
            }
          },
        });

        handleTokenRequest(tokenClient);
      } else {
        setError('Failed to authenticate');
      }
    },
    [metadata, error]
  );

  const handleAuth = (handleCredentialResponse, dispatch, fetchMetadata) => {
    let scriptElement = null;

    const initializeGoogleAuth = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: handleCredentialResponse,
      });

      const tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/drive.readonly',
        callback: (tokenResponse) => {
          dispatch(setAccessToken(tokenResponse.access_token));
          localStorage.setItem('googleAccessToken', tokenResponse.access_token);
          fetchMetadata(tokenResponse.access_token);
        },
      });

      tokenClient.requestAccessToken({prompt: ''});
    };

    scriptElement = document.createElement('script');
    scriptElement.src = 'https://accounts.google.com/gsi/client';
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.onload = initializeGoogleAuth;
    document.head.appendChild(scriptElement);

    return () => {
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  };

  useEffect(() => {
    if (!accessToken) {
      return handleAuth(handleCredentialResponse, dispatch, fetchMetadata);
    } else {
      fetchMetadata(accessToken);
    }
  }, [accessToken]);

  return (
    <div>
      {error && <div>{error}</div>}
      {metadata && (
        <>
          <h3>
            {metadata.name}{' '}
            <Link to={docLink} target="_blank" rel="noreferrer">
              <ExportOutlined />
            </Link>
          </h3>
          {widget?.owners && (
            <div>
              Owners:{' '}
              {metadata.owners?.map((owner) => owner?.displayName).join(', ')}
            </div>
          )}
          {(widget?.editor || widget?.modifiedTime) && (
            <div>
              Last edited: {metadata.lastModifyingUser?.displayName}
              <b>{tsToNy(metadata.modifiedTime)}</b>
            </div>
          )}
        </>
      )}
      <iframe
        frameBorder="0"
        height="800"
        src={setGoogleSource(docLink)}
        style={{border: '1px solid #939598'}}
        width="100%"
      />
    </div>
  );
};

export default DocViewer;
