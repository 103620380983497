import {ExportOutlined} from '@ant-design/icons';
import React from 'react';
import {Link} from 'react-router-dom';

const PDFViewer = ({widget}) => {
  return (
    <div>
      <h3>
        {widget.title || widget.source}{' '}
        <Link to={widget?.source} target="_blank" rel="noreferrer">
          <ExportOutlined />
        </Link>
      </h3>
      <iframe
        frameBorder="0"
        height="800"
        src={widget.source}
        style={{border: '1px solid #939598'}}
        width="100%"
      />
    </div>
  );
};

export default PDFViewer;
