import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {apiSlice} from 'api/apiSlice';
import {chatSlice} from 'store/chatSlice';
import {formSlice} from 'store/formSlice';
import {googleAuthSlice} from 'store/googleAuthSlice';

export const setupStore = (preloadedState) => {
  const store = configureStore({
    devTools: {
      stateSanitizer: (state) =>
        state.data ? {...state, data: '<<LONG_BLOB>>'} : state,
    },
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      chat: chatSlice.reducer,
      googleAuth: googleAuthSlice.reducer,
      form: formSlice.reducer,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(apiSlice.middleware),
  });

  setupListeners(store.dispatch);

  return store;
};

export const store = setupStore({});
