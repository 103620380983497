import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'utils/errorHandler';

const tagsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchTags: builder.query({
      query: () => `/tags/tag`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => ['Tags'],
    }),
    fetchCategories: builder.query({
      query: () => `/tags/category_tree`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => ['Categories'],
    }),
    createOrUpdateTag: builder.mutation({
      query: (tag) => ({
        url: tag.id ? `/tags/tag/${tag.id}/` : `/tags/tag/`,
        method: tag.id ? 'PUT' : 'POST',
        body: tag,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => ['Tags'],
    }),
    deleteTag: builder.mutation({
      query: (tag_id) => ({
        url: `/tags/tag/${tag_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => ['Tags'],
    }),
    createOrUpdateCategory: builder.mutation({
      query: (category) => ({
        url: category.id ? `/tags/category/${category.id}/` : `/tags/category/`,
        method: category.id ? 'PUT' : 'POST',
        body: category,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => ['Categories'],
    }),
    deleteCategory: builder.mutation({
      query: (category_id) => ({
        url: `/tags/category/${category_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => ['Categories'],
    }),
  }),
});

export const {
  useFetchTagsQuery,
  useFetchCategoriesQuery,
  useCreateOrUpdateTagMutation,
  useDeleteTagMutation,
  useCreateOrUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = tagsSlice;
