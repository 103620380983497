import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {Tag, Tooltip} from 'antd';
import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: white;
  &:hover {
    border-color: #aaa;
  }
  &:focus {
    border-color: #1890ff;
`;

const TagContainer = styled.div`
  display: flex;
  margin-top: 4px;
  justify-content: flex-end;
`;

const genderColor = (gender) => {
  switch (gender) {
    case 'F':
      return '#C0F7A5';
    case 'M':
      return '#F5F9BB';
    default:
      return '#FBB269';
  }
};

const genderName = (gender) => {
  switch (gender) {
    case 'F':
      return 'Female';
    case 'M':
      return 'Male';
    default:
      return 'Non-binary';
  }
};

const Student = ({
  handleToggleSelect,
  disabled,
  student,
  index,
  isSelected,
  selectionCount,
}) => {
  const handleStudentClicked = (e, studentId) => {
    e.preventDefault();
    handleToggleSelect(e, studentId);
  };

  const getStyleByState = (
    snapshot,
    isSelected,
    hide,
    wrongGrade,
    disabled
  ) => {
    if (hide) {
      return {
        display: 'none',
      };
    }

    if (snapshot.isDragging) {
      return {
        backgroundColor: '#f7d088',
      };
    }

    if (wrongGrade || disabled) {
      return {
        color: '#aaa',
        borderColor: '#ddd',
      };
    } else if (isSelected) {
      return {
        backgroundColor: '#fae7c7',
      };
    }

    return {};
  };

  return (
    <Draggable
      isDragDisabled={disabled || student.wrongGrade}
      draggableId={student.student_id}
      index={index}
    >
      {(provided, snapshot) => {
        const style = {
          ...provided.draggableProps.style,
          ...getStyleByState(
            snapshot,
            isSelected,
            student.hide,
            student.wrongGrade,
            disabled
          ),
        };
        return (
          <Container
            onClick={(e) => handleStudentClicked(e, student.student_id)}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            style={style}
            className="student"
          >
            {selectionCount > 1 && isSelected && snapshot.isDragging ? (
              <>{selectionCount} students selected</>
            ) : (
              <Tooltip
                title={
                  <div>
                    <div>{student.student_name}</div>
                    <div>
                      <b>{student.student_number}</b>
                    </div>
                    <div>{genderName(student.gender)}</div>
                    <div
                      style={{
                        color: `${student.has_ell ? 'black' : '#aaa'}`,
                      }}
                    >
                      {student.has_ell ? <CheckOutlined /> : <CloseOutlined />}
                      <b> ELL</b>
                    </div>
                    <div
                      style={{
                        color: `${student.has_iep ? 'black' : '#aaa'}`,
                      }}
                    >
                      {student.has_iep ? <CheckOutlined /> : <CloseOutlined />}
                      <b> IEP</b>
                    </div>
                    <div
                      style={{
                        color: `${student.has_504 ? 'black' : '#aaa'}`,
                      }}
                    >
                      {student.has_504 ? <CheckOutlined /> : <CloseOutlined />}
                      <b> 504</b>
                    </div>
                  </div>
                }
                color="rgba(247,231,206,0.9)"
                placement="right"
                overlayStyle={{
                  minWidth: '150px',
                }}
                overlayInnerStyle={{
                  color: 'black',
                  border: '1px solid #aaa',
                }}
                arrow={false}
              >
                {student.student_name}
                <TagContainer>
                  {student.has_504 && (
                    <Tag
                      style={{
                        color: '#000',
                      }}
                      color="#FDB0DE"
                    >
                      504
                    </Tag>
                  )}
                  {student.has_iep && (
                    <Tag
                      style={{
                        color: '#000',
                      }}
                      color="#8BFFFB"
                    >
                      i
                    </Tag>
                  )}
                  {student.has_ell && (
                    <Tag
                      style={{
                        color: '#000',
                      }}
                      color="#EABBF7"
                    >
                      E
                    </Tag>
                  )}
                  <Tag
                    style={{
                      color: '#000',
                    }}
                    color={genderColor(student.gender)}
                  >
                    {student.gender || '-'}
                  </Tag>
                </TagContainer>
              </Tooltip>
            )}
          </Container>
        );
      }}
    </Draggable>
  );
};

export default Student;
