import {CaretRightOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';
import {Button, Collapse, Form, Spin, Tooltip, TreeSelect} from 'antd';
import {useFetchTagsQuery} from 'api/tagsSlice';
import CatalogItem from 'components/genericComponents/CatalogItem';
import SelectOptions from 'components/genericComponents/SelectOptions';
import {createNestedDataFilter} from 'hooks/NestedFilter';
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const {Panel} = Collapse;

export const useFilter = (initialData) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTags, setFilterTags] = useState([]);

  const {combineFilters, filterByTags, getActiveKeys, getInitialData} =
    createNestedDataFilter({
      fuseOptions: {
        keys: ['title'],
        threshold: 0.4,
      },
    });

  const filteredData = useMemo(() => {
    return combineFilters(initialData, searchTerm, filterTags);
  }, [initialData, searchTerm, filterTags]);

  const activeKeys = useMemo(() => {
    return getActiveKeys(initialData);
  }, [initialData]);

  const defaultData = useMemo(() => {
    const filteredByTags = filterByTags(initialData, filterTags);
    return getInitialData(filteredByTags);
  }, [initialData, filterTags]);

  return {
    activeKeys,
    filteredData,
    filterTags,
    defaultData,
    searchTerm,
    setFilterTags,
    setSearchTerm,
  };
};

const Catalog = ({
  buttons = [],
  editorPermission = false,
  extraTags = [],
  loading = false,
  pages,
  path,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const editorMode = location.pathname.includes('/editor') && editorPermission;

  const {data: tags, isLoading: tagsLoading} = useFetchTagsQuery();

  const [filterTagOptions, setFilterTagOptions] = useState([]);

  const {
    activeKeys,
    filteredData,
    filterTags,
    defaultData,
    searchTerm,
    setFilterTags,
    setSearchTerm,
  } = useFilter(pages?.children || []);

  useEffect(() => {
    const options =
      tags?.map((tag) => ({
        ...tag,
        color: tag.color,
        label: tag.name,
        value: tag.id,
      })) || [];
    if (editorMode) {
      options.unshift(...extraTags);
    }
    setFilterTagOptions(options);
  }, [tags, editorMode]);

  useEffect(() => {
    if (!filterTagOptions?.length) return;

    const params = new URLSearchParams(location.search);
    const tagParams = params.getAll('tag');
    const newTags =
      tagParams
        .map((t) => filterTagOptions?.find((d) => d.name === t))
        .filter((t) => t) || [];
    setFilterTags(newTags);
  }, [location.search, filterTagOptions]);

  const renderCollapse = (items) =>
    items?.map((item) => {
      if (item.type === 'node' && !item.children?.length) {
        return null;
      }
      return item.type === 'node' ? (
        <Panel
          className="site-collapse-custom-content"
          header={<h3>{item.title}</h3>}
          key={item.value}
        >
          <Collapse
            bordered={false}
            collapsible="icon"
            defaultActiveKey={activeKeys}
            expandIcon={({isActive}) => (
              <CaretRightOutlined
                style={{paddingTop: 8}}
                rotate={isActive ? 90 : 0}
              />
            )}
            ghost
          >
            {renderCollapse(item.children)}
          </Collapse>
        </Panel>
      ) : (
        <CatalogItem
          editorMode={editorMode}
          item={item}
          key={item.value}
          path={path}
          onChangeFilterTags={onChangeFilterTags}
        />
      );
    });

  const onChangeFilterTags = (value) => {
    const search = new URLSearchParams(location.search);
    // delete all tag params
    search.delete('tag');
    value.forEach((t) => {
      const tag = filterTagOptions?.find((d) => d.id === t);
      search.append('tag', tag?.name);
    });
    navigate(`${location.pathname}?${search.toString()}`);
  };

  return (
    <>
      <Form className="flex-row" style={{margin: '15px 0'}}>
        <Form.Item noStyle>
          <TreeSelect
            allowClear={true}
            className="flex-row"
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            filterTreeNode={false}
            onSearch={(value) => setSearchTerm(value)}
            onSelect={(value) =>
              navigate(`/${path}/${value}${editorMode ? '/editor' : ''}`)
            }
            placeholder="Search"
            searchValue={searchTerm}
            showSearch
            size="large"
            style={{width: '350px'}}
            treeData={filteredData}
            treeDefaultExpandAll
          />
        </Form.Item>
        <Form.Item noStyle>
          <SelectOptions
            allowClear={true}
            label="Filter Tags"
            mode="tags"
            multiple={true}
            onChange={onChangeFilterTags}
            options={filterTagOptions}
            required={false}
            style={{width: '350px'}}
            value={filterTags.map((t) => t.id)}
          />
        </Form.Item>
        {editorMode && (
          <>
            <div style={{flex: 1}} />
            {buttons.map((button) => (
              <Button key={button.label} type="primary" href={button.href}>
                {button.label}
              </Button>
            ))}
            <Button
              href="/tags_categories"
              key="tags_categories"
              type="primary"
            >
              Manage Tags and Categories
            </Button>
          </>
        )}
      </Form>
      <Spin spinning={loading || tagsLoading}>
        {defaultData?.length ? (
          <Collapse
            bordered={false}
            collapsible="icon"
            defaultActiveKey={activeKeys}
            expandIcon={({isActive}) => (
              <CaretRightOutlined
                style={{paddingTop: 6}}
                rotate={isActive ? 90 : 0}
              />
            )}
            ghost
          >
            {renderCollapse(defaultData)}
          </Collapse>
        ) : (
          <p>No pages found.</p>
        )}
      </Spin>
      {editorPermission && (
        <Tooltip title={editorMode ? 'Preview mode' : 'Editor mode'}>
          <Button
            onClick={() => navigate(`/${path}${editorMode ? '' : '/editor'}`)}
            type="primary"
            style={{
              fontSize: '1.8em',
              height: '50px',
              left: 0,
              position: 'fixed',
              top: '15%',
              zIndex: 1001,
            }}
          >
            {editorMode ? <EyeOutlined /> : <EditOutlined />}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default Catalog;
