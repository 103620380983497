import {HotTable} from '@handsontable/react';
import {Alert, Button, Popconfirm, Segmented, Spin, message} from 'antd';
import {
  useFetchColumnOptionsQuery,
  useFetchSeedDataQuery,
  useSetSeedDataMutation,
} from 'api/seedsSlice';
import {
  CustomAutocompleteEditor,
  columnTypes,
  dropdownValidator,
  iconRenderer,
  multiSelectRenderer,
  multiselectValidator,
  safeHTMLRenderer,
  settings,
  textValidator,
} from 'components/seeds/HandsonSettings';
import Fuse from 'fuse.js';
import _, {isEqual} from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  selectIsFormEdited,
  updateFormField,
  updateOriginalField,
} from 'store/formSlice';
import {statuses} from 'utils/consts';
import {handleApiError} from 'utils/errorHandler';
import {generateUniqueId, stringToFloat} from 'utils/helpers';

// statuses:
// - removed: the row is empty
// - duplicate: the row has duplicate primary keys or unique columns
// - edited: the row has been edited
// - invalid: the row has invalid cells (list of invalid cells)
// - blank: the row has empty cells (list of empty cells)
// - newRow: the row is new

const TableEditor = ({seedId}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const isDirty = useSelector((state) =>
    selectIsFormEdited(state, `source_manager_${seedId}`)
  );

  const [columns, setColumns] = useState([]);
  const [autocompleteCols, setAutocompleteCols] = useState('');
  const [statusCounts, setStatusCounts] = useState({
    removed: 0,
    duplicate: 0,
    edited: 0,
    invalid: 0,
    blank: 0,
    newRow: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [loading, setLoading] = useState(false);
  const [primaryKeyCols, setPrimaryKeyCols] = useState([]);
  const [isDiff, setIsDiff] = useState(false); // if the draft data is different from the final data
  const [initialData, setInitialData] = useState([]);
  const [initialDraftData, setInitialDraftData] = useState([]);

  const [saveSeedData] = useSetSeedDataMutation();
  const {data: seedData, isLoading: loadingData} = useFetchSeedDataQuery(
    {
      name: seedId,
      version: params.get('version') || 'latest',
    },
    {
      skip: !seedId,
    }
  );
  const {data: autocompleteOptions, isLoading: loadingOptions} =
    useFetchColumnOptionsQuery(autocompleteCols, {
      skip: autocompleteCols === '',
    });

  const hotRef = useRef(null);

  useEffect(() => {
    if (Object.keys(autocompleteOptions?.errors ?? {}).length) {
      handleApiError({
        status: 199,
        data: {detail: autocompleteOptions.errors},
      });
    }
  }, [autocompleteOptions]);

  useEffect(() => {
    if (!initialData.length) return;
    const draftData = getDraftData();
    dispatch(
      updateOriginalField({
        id: `source_manager_${seedId}`,
        field: 'table_data',
        value: _.cloneDeep(draftData),
      })
    );
    dispatch(
      updateFormField({
        id: `source_manager_${seedId}`,
        field: 'table_data',
        value: _.cloneDeep(draftData),
      })
    );
  }, [initialData]);

  useEffect(() => {
    /*
     * Set up the columns for the table
     * If the seed data is not available, return
     * If the seed data has a message, display the message and return
     * If the seed data has no columns, return
     */
    if (!seedData || !seedId) return;
    let cols = seedData?.metadata?.columns?.length
      ? [...seedData?.metadata.columns]
      : [];

    const tempCols = [
      {name: '', type: 'text', key: 'status'},
      {name: '___primaryKey', type: 'text', key: 'primaryKey'},
    ];
    cols.forEach((col) => {
      const baseCol = {
        ...col,
        linkedCols: col.linked_columns?.length
          ? [
              {source_column: col.source_column, name: col.name},
              ...col.linked_columns,
            ]
          : undefined,
      };
      tempCols.push(baseCol);
      tempCols.push({
        ...col,
        name: `${col.name}___original`,
        is_unique: false,
      });

      if (col.type === 'autocomplete' && col.linked_columns?.length) {
        const colData = {
          ...col,
          linkedCols: [
            {source_column: col.source_column, name: col.name},
            ...col.linked_columns,
          ],
        };
        col.linked_columns.forEach((linkedCol) => {
          tempCols.push({
            ...colData,
            ...linkedCol,
            is_unique: false,
          });
          tempCols.push({
            ...colData,
            ...linkedCol,
            name: `${linkedCol.name}___original`,
            is_unique: false,
          });
        });
      }
    });

    if (seedData?.data?.length) {
      Object.keys(seedData.data[0]).forEach((key) => {
        if (!tempCols.find((col) => col.name === key)) {
          const newCol = {
            name: key,
            type: 'text',
            key,
            col_id: generateUniqueId([], 'int'),
            linked_columns: [],
            removed_col: true,
          };
          tempCols.push(newCol);
          tempCols.push({...newCol, name: `${key}___original`});
        }
      });
    }

    const tableCols = tempCols.map(getColOptions);
    setColumns(tableCols);
  }, [seedData, autocompleteOptions]);

  useEffect(() => {
    /*
     * Set up the autocomplete options for the table
     * If the columns are not available, return
     * If the columns have no autocomplete columns, return
     * If the columns have no source_table or source_column, return
     * If the columns have no linked columns, return
     */
    const params = new URLSearchParams();
    columns.forEach((col) => {
      if (
        col.type === 'autocomplete' &&
        col.source_table &&
        col.source_column
      ) {
        const sourceCols = params.get(col.source_table)?.split(',') || [];
        if (!sourceCols.includes(col.source_column)) {
          sourceCols.push(col.source_column);
        }
        col.linkedCols?.forEach((linkedCol) => {
          if (!sourceCols.includes(linkedCol.source_column)) {
            sourceCols.push(linkedCol.source_column);
          }
        });
        params.set(col.source_table, sourceCols.join(','));
      }
    });
    setAutocompleteCols(params.toString());
  }, [columns]);

  useEffect(() => {
    /*
     * Set up the table with the seed data
     * If the seed data is not available, return
     * If the seed data has a message, display the message and return
     */
    if (!seedData || !columns) return;
    if (seedData.message) {
      message.error({
        content: seedData.message,
        duration: 10,
        key: 'dataSourceError',
      });
      return;
    }
    const hot = hotRef?.current?.hotInstance;
    if (!hot) return;
    hot.updateSettings({
      data: initialData,
      colHeaders: columns?.map((col) => col.title),
      columns,
      hiddenColumns: {
        columns: getHiddenColumns(),
        copyPasteEnabled: false,
      },
      afterChange: onAfterChange,
      afterGetColHeader: onAfterGetColHeader,
      // afterSetDataAtCell: onAfterChange,
      beforeAutofill: onBeforeAutofill,
      beforeChange: onBeforeChange,
      beforePaste: onBeforePaste,
      beforeRemoveRow: onBeforeRemoveRow,
    });
  }, [columns, initialData]);

  useEffect(() => {
    // If data and draft_data are different, set isDiff to true
    if (!seedData) return;
    const data = seedData.data;
    const draftData = seedData.draft_data;
    if (!data || !draftData) return;
    setIsDiff(!isEqual(data, draftData));
    setInitialDraftData(draftData);
  }, [seedData]);

  const onBeforePaste = (data, coords) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    // if the column is read only, don't allow pasting - display an error message
    if (coords[0].startCol === 0 || columns[coords[0].startCol].is_read_only) {
      message.error({
        content: 'Cannot paste to read only columns',
        duration: 2,
      });
      return false;
    }
    // first, set the table to loading so the user knows the data is being pasted
    setLoading(true);

    // we only want to paste to visible columns, so we need to match the columns to the data
    const visibleCols = [];
    let index = coords[0].startCol;
    // get the columns to paste to
    while (visibleCols.length < data[0].length && index < columns.length) {
      const col = columns[index];
      if (col.name && !col.name.includes('___')) {
        visibleCols.push({
          ...col,
          index,
        });
      }
      index++;
    }
    // we want columns with data_type INTEGER to be converted to numbers
    const dataToPaste = data
      .map(
        (row) =>
          row.map((val, i) => {
            const colDataType = visibleCols[i]?.data_type;
            return colDataType === 'INTEGER' ? stringToFloat(val) : val;
          })
        // if the row has more columns than the visible columns, remove the extra columns
      )
      .map((row) => row.slice(0, visibleCols.length));

    // if the data is only one row, we want to paste the same data to all the destination rows
    // if the data is multiple rows, we want to paste the data to the corresponding rows
    const newData = [];
    if (dataToPaste.length === 1) {
      const destRows = Array.from(
        {length: coords[0].endRow - coords[0].startRow + 1},
        (v, k) => k + coords[0].startRow
      );
      destRows.forEach((row) => {
        dataToPaste[0].forEach((val, i) => {
          newData.push([row, visibleCols[i].index, val]);
        });
      });
    } else {
      const destRows = Array.from(
        {length: data.length},
        (v, k) => k + coords[0].startRow
      );
      dataToPaste.forEach((row, i) => {
        row.forEach((val, j) => {
          newData.push([destRows[i], visibleCols[j]?.index, val]);
        });
      });
    }
    runActions([
      {
        method: 'setDataAtCell',
        data: newData,
      },
    ]);

    // set the table to not loading
    setLoading(false);
    return false;
  };

  const onBeforeAutofill = (
    selectionData,
    sourceRange,
    targetRange,
    direction
  ) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    setLoading(true);

    const autofillSourceCols = [];
    for (let i = sourceRange.from.col; i <= sourceRange.to.col; i++) {
      const colToProp = hot.colToProp(i);
      const col = columns.find((c) => c.name === colToProp);
      if (!col.name.includes('___')) {
        autofillSourceCols.push(col);
      }
    }
    // check if any of the cells in the source range are linked columns
    const autofillCols = [];
    for (const col of autofillSourceCols) {
      if (col.linkedCols?.length) {
        col.linkedCols.forEach((linkedCol) => {
          const col = columns.find((c) => c.name === linkedCol.name);
          if (col && autofillCols.indexOf(col) === -1) autofillCols.push(col);
        });
      } else {
        autofillCols.push(col);
      }
    }
    const rowsToUpdate = Array.from(
      {length: targetRange.to.row - targetRange.from.row + 1},
      (v, k) => k + targetRange.from.row
    );
    const dataToUpdate = [];
    for (const col of autofillCols) {
      // get the data from the source column
      const sourceColIdx = hot.propToCol(col.name);
      const sourceCellData = hot.getDataAtCell(
        sourceRange.from.row,
        sourceColIdx
      );
      // for each row in the target range, add an entry to the dataToUpdate array
      rowsToUpdate.forEach((row) => {
        dataToUpdate.push([row, sourceColIdx, sourceCellData]);
      });
    }
    runActions([
      {
        method: 'setDataAtCell',
        data: dataToUpdate,
      },
    ]);
    setLoading(false);
  };

  const handleClickTag = (item, row, prop) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    const oldData =
      hot.getDataAtRowProp(row, prop)?.toString().split(';').filter(Boolean) ||
      [];
    const data = oldData.includes(item)
      ? oldData.filter((val) => val !== item)
      : [...oldData, item];

    const col = hot.propToCol(prop);
    hot.setDataAtCell(row, col, data.join(';'));
    onAfterChange([[row, prop, oldData.join(';'), data.join(';')]], 'edit');
  };

  const addPrimaryKeys = (data, cols, keyName) => {
    return data.map((row) => {
      const newRow = columns.reduce((acc, col) => {
        acc[col.name] = row[col.name] ?? '';
        return acc;
      }, {});
      const uniqueKey =
        cols
          .map((col) => row[col] ?? '')
          .join('___')
          .replace(/_+/g, '') || undefined;
      newRow[keyName] = uniqueKey;
      return newRow;
    });
  };

  const getPrimaryKeyCols = (primaryKey = [], useLinkedCols = true) => {
    const cols = [];
    for (const col of primaryKey) {
      const linkedCols = columns.find((c) => c.name === col)?.linkedCols;
      if (linkedCols?.length && useLinkedCols) {
        linkedCols.forEach((c) => c.name && cols.push(c.name));
      } else {
        cols.push(col);
      }
    }
    return cols;
  };

  useEffect(() => {
    /*
     * Create a new table with the original data and the draft data
     * If the seed data is not available, return an empty array
     * Add the primary key to the data
     */
    if (!seedData) return;
    const cols = getPrimaryKeyCols(seedData?.metadata?.primary_key, false);
    setPrimaryKeyCols(cols);

    const draftData = seedData.draft_data;

    const draftDataWithPrimaryKeys = addPrimaryKeys(
      draftData,
      cols,
      '___primaryKey'
    );
    const originalData = addPrimaryKeys(
      [...(seedData.data ?? [])],
      cols,
      '___primaryKey'
    );

    const uniquePrimaryKeys = [
      ...new Set(
        draftDataWithPrimaryKeys
          .map((row) => row.___primaryKey)
          .concat(originalData.map((row) => row.___primaryKey))
      ),
    ];

    const newTableData = [];
    for (const uniqueKey of uniquePrimaryKeys) {
      const originalRow = originalData.filter(
        (row) => row.___primaryKey === uniqueKey
      );
      const draftRow = draftDataWithPrimaryKeys.filter(
        (row) => row.___primaryKey === uniqueKey
      );

      /*
       * create a new row with the original data and the draft data
       * if there is more than one row with the same primary key, add all the rows, unless the entire row is empty
       * numeric values are converted to numbers
       */
      for (let i = 0; i < Math.max(originalRow.length, draftRow.length); i++) {
        const newRow = {};
        for (const col of columns) {
          if (col.name === '') continue;
          if (col.name.includes('___original')) {
            const dataCol = col.name.split('___')[0];
            newRow[col.name] = originalRow[i]?.[dataCol] ?? '';
          } else {
            newRow[col.name] = draftRow[i]?.[col.name] ?? '';
          }
          if (col.data_type === 'INTEGER') {
            newRow[col.name] = stringToFloat(newRow[col.name]);
          } else if (col.data_type === 'STRING') {
            newRow[col.name] = newRow[col.name]?.toString();
          }
        }
        if (Object.values(newRow).join('') !== '') {
          newTableData.push(newRow);
        }
      }
    }
    setInitialData(newTableData);
  }, [columns, seedData]);

  const getColOptions = useCallback(
    (col) => {
      const options = {
        ...col,
        data: col.name,
        title: col.title ?? col.name,
        allowEmpty: true,
        allowInvalid: true,
        renderer: safeHTMLRenderer,
        validator: textValidator,
        width: 200,
      };
      if (col.key === 'status') {
        options.renderer = iconRenderer;
        options.editor = false;
        options.readOnly = true;
        options.width = 55;
      } else if (col.type === 'dropdown') {
        options.strict = true;
        options.renderer = col.allow_multiple
          ? multiSelectRenderer
          : safeHTMLRenderer;
        options.validator = col.allow_multiple
          ? multiselectValidator
          : dropdownValidator;
        options.source = !col.source
          ? []
          : typeof col.source === 'string'
            ? JSON.parse(col?.source.replace(/'/g, '"') ?? '[]')
            : col.source;
        options.colOptions = options.source;
        options.onClickTag = handleClickTag;
      } else if (col.type === 'autocomplete') {
        options.strict = true;
        options.renderer = safeHTMLRenderer;
        options.editor = CustomAutocompleteEditor;
        options.validator = dropdownValidator;
        if (col.source_table) {
          const keys = col.linkedCols?.map((c) => c.source_column);
          options.allOptions = [
            ...new Set(
              // concatenate all the linked columns and remove duplicates
              autocompleteOptions?.[col.source_table]
                ?.map((row) =>
                  keys?.length
                    ? keys
                        .reduce((acc, key) => `${acc}${row[key] ?? ''} | `, '')
                        .slice(0, -3)
                    : row[col.source_column]
                )
                ?.sort()
                ?.filter((val) => val)
            ),
          ];
          options.colOptions = [
            ...new Set(
              autocompleteOptions?.[col.source_table]
                ?.map((row) => row[col.source_column])
                ?.sort()
                ?.filter((val) => val)
            ),
          ];
          if (col.data_type === 'INTEGER') {
            options.colOptions = options.colOptions.map((val) =>
              stringToFloat(val)
            );
          }
          options.source = function (query, process) {
            if (!query) {
              const matches = options.allOptions.slice(0, 100);
              matches.unshift('');
              return process(matches);
            }

            // const queriesArray = query.toString().split(' ');
            // const minMatchCharLength = queriesArray.reduce(
            //   (acc, q) => (q.length < acc ? q.length : acc),
            //   1
            // );
            const fuse = new Fuse(options.allOptions, {
              keys: keys ?? [col.source_column],
              includeMatches: true,
              includeScore: true,
              threshold: 0.4,
              // ignoreLocation: true,
              // minMatchCharLength: minMatchCharLength,
              useExtendedSearch: true,
              // shouldSort: false,
              getFn: (obj) => {
                return obj.toString();
              },
            });

            const results = fuse.search(query);
            const matches = results.map((row) => row.item).slice(0, 100);
            matches.unshift('');
            return process(matches);
          };
        } else {
          options.source = [];
          options.colOptions = [];
        }
      }
      if (col.removed_col || col.is_read_only) {
        options.editor = false;
        options.readOnly = true;
      }
      return options;
    },
    [autocompleteOptions]
  );

  const getHiddenColumns = useCallback(() => {
    return columns
      ?.map((col, i) => ({...col, index: i}))
      ?.filter((col) => col.name.indexOf('___') > -1)
      ?.map((col) => col.index);
  }, [columns]);

  const onAfterGetColHeader = (col, TH) => {
    // TODO: this is being called multiple times, need to figure out why
    const colData = columns[col];
    if (!colData || colData.name.includes('___')) return;

    const title = colData.removed_col
      ? 'Removed'
      : colData.type === 'dropdown'
        ? `${colData.allow_multiple ? 'Multiselect ' : ''}Options: \n${colData.source.join('\n')}`
        : colData.type === 'autocomplete'
          ? `Source: \n${colData.source_table}.${colData.source_column}${colData.allow_multiple ? '\nMultiselect' : ''}`
          : columnTypes.find((type) => type.value === colData.type)?.label;

    TH.setAttribute('title', title);
    if (colData?.removed_col) {
      TH.className = 'diff-removed-cell';
    }
    if (col === 0) {
      const button = TH.querySelector('.changeType');
      if (button) {
        button.style.display = 'none';
      }
    }
    return TH;
  };

  const onBeforeRemoveRow = (index, amount, physicalRows, source) => {
    // instead of removing the row, remove the data from visible columns
    // if the row is new, (e.g., has no original data) remove the entire row
    // NOTE: I'm allowing the user to remove all visible values, including read only columns, since I assume there will be use cases where the user wants a row to be removed entirely
    if (source === undefined) return;
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    const allActions = [];
    const rowsToRemove = [];

    for (let row of physicalRows) {
      const data = hot.getDataAtRow(row);
      if (data.every((val) => !val)) {
        rowsToRemove.push(row);
      } else {
        for (let j = 0; j < hot.countCols(); j++) {
          const prop = hot.colToProp(j);
          if (prop.includes('___') || j === 0) continue;
          allActions.push([row, prop, '']);
        }
      }
    }
    // if all the rows in the table are removed, keep the first one
    if (hot.countRows() === rowsToRemove.length) {
      rowsToRemove.pop();
    }
    runActions([
      {
        method: 'setSourceDataAtCell',
        data: allActions,
      },
      ...rowsToRemove.map((row) => ({
        method: 'removeRow',
        row,
      })),
    ]);
    return false;
  };

  const onBeforeChange = (changes, source) => {
    if (!changes) return;
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    // if the data_type is INTEGER, convert the value to a number
    changes.forEach((change) => {
      const [row, prop, oldValue, newValue] = change;
      const col = hot.propToCol(prop);
      const cellMeta = hot.getCellMeta(row, col);

      // cancel changes to hidden columns
      if (cellMeta?.name.includes('___')) {
        change[3] = oldValue;
      } else if (cellMeta.data_type === 'INTEGER') {
        change[3] = stringToFloat(newValue);
      } else {
        change[3] = newValue?.toString().trim() ?? '';
      }
    });
  };

  const checkIfRowIsEdited = (
    _edited,
    actions,
    cellMeta,
    i,
    newData,
    newDataCols,
    originalData,
    statusValue
  ) => {
    // check if the row has been edited, and if so, which columns
    const _editedCols = [];
    newDataCols.forEach((col, j) => {
      if (newData[j] !== originalData[j]) {
        _editedCols.push(col.name);
      }
    });
    // if the row has been edited, add the edited columns to the edited object and the statusValue array
    if (_editedCols.length) {
      if (!_edited[i]) _edited[i] = [];
      _edited[i].push(_editedCols.join('\n'));
      statusValue.push('edited');
    }
    // update the cell meta with the edited columns
    if (_editedCols.join('\n') !== (cellMeta.edited ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'edited',
        value: _editedCols.length
          ? 'Edited Columns:\n' + _editedCols.join('\n')
          : '',
      });
    }
  };

  const checkIfRowHasDuplicates = (
    _duplicates,
    actions,
    cellMeta,
    i,
    primaryKeys,
    statusValue,
    tableData,
    uniqueColsVals
  ) => {
    // check if the row has duplicate primary keys or unique columns
    const _duplicateCols = [];
    const primaryKey = primaryKeys[i];
    if (Object.keys(uniqueColsVals)?.length) {
      const uniqueCols = Object.keys(uniqueColsVals);
      for (const col of uniqueCols) {
        const val = uniqueColsVals[col][i];
        if (uniqueColsVals[col].filter((v) => v === val).length > 1) {
          _duplicateCols.push(col);
        }
      }
    }
    // if the table has a primary key, check if the primary key is a duplicate
    if (
      primaryKeyCols.length &&
      primaryKeys.filter((key) => key === primaryKey).length > 1
    ) {
      _duplicateCols.push('Primary Key');
    } else {
      // if the table has no primary key, check if the row has duplicate values in the entire row
      if (tableData.filter((row) => isEqual(row, tableData[i])).length > 1) {
        _duplicateCols.push('Entire Row');
      }
    }
    // if the row has duplicate primary keys or unique columns, add to the duplicates object and the statusValue array
    if (_duplicateCols.length) {
      if (!_duplicates[i]) _duplicates[i] = [];
      _duplicates[i].push(_duplicateCols.join('\n'));
      statusValue.push('duplicate');
    }
    // update the cell meta with the duplicate columns
    if (_duplicateCols.join('\n') !== (cellMeta.duplicate ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'duplicate',
        value: _duplicateCols.length
          ? 'Duplicate Columns:\n' + _duplicateCols.join('\n')
          : '',
      });
    }
  };

  const checkIfRowHasInvalids = (
    _invalid,
    actions,
    cellMeta,
    i,
    newDataCols,
    statusValue,
    tableData
  ) => {
    const _invalidCols = [];
    newDataCols.forEach((col) => {
      // only check cells that are not allowed to be invalid
      if (col.allow_invalid || col.name.includes('___') || col.name === '')
        return;
      let invalidMessage;
      // if the cell is a dropdown or autocomplete, the value must be in the source
      if (col.type === 'dropdown' || col.type === 'autocomplete') {
        invalidMessage = 'Value not in source';
      } else if (col.data_type === 'INTEGER') {
        invalidMessage = 'Value must be an integer';
      } else if (col.data_type === 'DATE') {
        invalidMessage = 'Value must be a date';
      } else {
        invalidMessage = 'Invalid Value';
      }

      !col.validator(tableData[i][col.name], (valid) => {
        if (!valid) {
          _invalidCols.push(col.name + ': ' + invalidMessage);
        }
      });
    });
    // if the row has invalid cells, add to the invalid object and the statusValue array
    if (_invalidCols.length) {
      if (!_invalid[i]) _invalid[i] = [];
      _invalid[i].push(_invalidCols.join('\n'));
      statusValue.push('invalid');
    }
    // update the cell meta with the invalid columns
    if (_invalidCols.join('\n') !== (cellMeta.invalid ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'invalid',
        value: _invalidCols.length
          ? 'Invalid Values:\n' + _invalidCols.join('\n')
          : '',
      });
    }
  };

  const checkIfRowHasBlanks = (
    _blanks,
    actions,
    cellMeta,
    i,
    newDataCols,
    rowData,
    statusValue
  ) => {
    const _blankCols = [];
    newDataCols.forEach((col) => {
      // only check cells that are not allowed to be empty
      if (col.allow_empty || col.name.includes('___') || col.name === '')
        return;
      const blank = !rowData[col.name];
      if (blank) {
        _blankCols.push(col.name);
      }
    });
    // if the row has blank cells, add to the blanks object and the statusValue array
    if (_blankCols.length) {
      if (!_blanks[i]) _blanks[i] = [];
      _blanks[i].push(_blankCols.join('\n'));
      statusValue.push('blank');
    }
    // update the cell meta with the blank columns
    if (_blankCols.join('\n') !== (cellMeta.blank ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'blank',
        value: _blankCols.length
          ? 'Blank Columns:\n' + _blankCols.join('\n')
          : '',
      });
    }
  };

  const getRowStatuses = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    // set the button to loading
    // setCalculatingStatuses(true);
    const actions = [];

    clearFilters();

    const tableData = hot.getSourceData();

    const _blanks = {};
    const _duplicates = {};
    const _edited = {};
    const _invalid = {};
    const _newRows = [];
    const _removed = [];

    const uniqueColsVals = {};
    const uniqueCols = columns.filter(
      (col) => col.is_unique && !col.name.includes('___')
    );
    for (const col of uniqueCols) {
      const colVals = tableData.map((row) => {
        const val = col.linkedCols
          ? col.linkedCols.map((linkedCol) => row[linkedCol.name]).join('___')
          : row[col.name];
        return val;
      });
      uniqueColsVals[col.name] = colVals;
    }
    const primaryKeys = tableData.map((row) => row.___primaryKey);

    const newDataCols = columns.filter(
      (col) =>
        !col.name.includes('___') &&
        col.name !== '' &&
        !col.name.startsWith('___')
    );
    const originalDataCols = columns.filter(
      (col) => col.name.includes('___original') && !col.name.startsWith('___')
    );

    for (let i = 0; i < tableData.length; i++) {
      const rowData = tableData[i];
      const cellMeta = hot.getCellMeta(i, 0);
      const originalData = originalDataCols.map((col) => rowData[col.name]);
      const newData = newDataCols.map((col) => rowData[col.name]);

      const currentStatusValue = hot.getSourceDataAtCell(i, 0);
      const statusValue = [];

      // check if the row has been removed:
      // if all the visible cells in the row are empty, and the row is not new, mark the row as removed
      if (newData.every((val) => !val) && !originalData.every((val) => !val)) {
        _removed.push(i);
        for (const status of Object.keys(statuses)) {
          if (status === 'removed') {
            if (!cellMeta.removed) {
              actions.push({
                method: 'setCellMeta',
                row: i,
                col: 0,
                key: 'removed',
                value: 'Removed',
              });
            }
          } else if (cellMeta[status]) {
            actions.push({
              method: 'setCellMeta',
              row: i,
              col: 0,
              key: status,
              value: false,
            });
          }
        }
        statusValue.push('removed');
      } else {
        // check if the row is a new row:
        // if some visible cells in the row are not empty, and original data is empty, mark the row as new
        if (originalData.every((val) => !val) && newData.some((val) => val)) {
          _newRows.push(i);
          if (!cellMeta.newRow) {
            actions.push({
              method: 'setCellMeta',
              row: i,
              col: 0,
              key: 'newRow',
              value: 'New Row',
            });
          }
          statusValue.push('newRow');
        } else {
          // check if the row has been edited, and if so, which columns
          checkIfRowIsEdited(
            _edited,
            actions,
            cellMeta,
            i,
            newData,
            newDataCols,
            originalData,
            statusValue
          );
        }

        // check if the row has blanks that are required
        checkIfRowHasBlanks(
          _blanks,
          actions,
          cellMeta,
          i,
          newDataCols,
          rowData,
          statusValue
        );

        // check if the row has invalid cells
        checkIfRowHasInvalids(
          _invalid,
          actions,
          cellMeta,
          i,
          newDataCols,
          statusValue,
          tableData
        );

        // check if the row has duplicate primary keys or unique columns
        checkIfRowHasDuplicates(
          _duplicates,
          actions,
          cellMeta,
          i,
          primaryKeys,
          statusValue,
          tableData,
          uniqueColsVals
        );
      }

      if (statusValue.length && statusValue.join('\n') !== currentStatusValue) {
        actions.push({
          method: 'setDataAtCell',
          row: i,
          col: 0,
          value: statusValue.join('\n'),
        });
      }
    }

    if (actions.length) runActions(actions);

    const rowStatuses = {
      blank: Object.keys(_blanks).length,
      duplicate: Object.keys(_duplicates).length,
      edited: Object.keys(_edited).length,
      invalid: Object.keys(_invalid).length,
      newRow: _newRows.length,
      removed: _removed.length,
    };

    setStatusCounts(rowStatuses);
    // setCalculatingStatuses(false);
  };

  const runActions = (allActions) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot || !allActions?.length) return;

    // consolidate all setDataAtCell actions into one
    const setDataAtCellActions = allActions.filter(
      (action) => action.method === 'setDataAtCell'
    );
    const otherActions = allActions.filter(
      (action) => action.method !== 'setDataAtCell'
    );

    const allSetDataActions = [];
    // if data that is part of the primary key is changed, recalculate the primary key
    const rowsToRecalculatePrimaryKey = new Set();
    for (const action of setDataAtCellActions) {
      if (Array.isArray(action.data)) {
        action.data.forEach((data) => {
          const [row, col, value] = data;
          const prop = hot.colToProp(col);
          if (primaryKeyCols.includes(prop)) {
            rowsToRecalculatePrimaryKey.add(row);
          }
          allSetDataActions.push([row, col, value]);
        });
      } else {
        const {row, col, value} = action;
        allSetDataActions.push([row, col, value]);
        const prop = hot.colToProp(col);
        if (primaryKeyCols.includes(prop)) {
          rowsToRecalculatePrimaryKey.add(row);
        }
      }
    }
    if (allSetDataActions.length) {
      otherActions.push({
        method: 'setDataAtCell',
        data: allSetDataActions,
      });
    }

    hot.batch(() => {
      otherActions.forEach((action) => {
        switch (action.method) {
          case 'validateCell':
            hot.validateCell(action.data, action.cellMeta, (valid) => {});
            break;
          case 'setCellMeta':
            hot.setCellMeta(action.row, action.col, action.key, action.value);
            break;
          case 'setDataAtCell':
            if (Array.isArray(action.data)) {
              hot.setDataAtCell(action.data);
            } else {
              hot.setDataAtCell(action.row, action.col, action.value);
            }
            break;
          case 'setSourceDataAtCell':
            if (Array.isArray(action.data)) {
              hot.setSourceDataAtCell(action.data);
            } else {
              hot.setSourceDataAtCell(action.row, action.col, action.value);
            }
            break;
          case 'removeRow':
            hot.alter('remove_row', action.row);
          default:
            break;
        }
      });
      hot.render();
    });
    const newPrimaryKeys = [];
    const primaryKeyColIdx = hot.propToCol('___primaryKey');
    rowsToRecalculatePrimaryKey.forEach((row) => {
      let newPrimaryKey = primaryKeyCols
        .map((col) => hot.getDataAtCell(row, hot.propToCol(col)))
        .join('___');
      if (newPrimaryKey.replaceAll('_', '') === '') {
        newPrimaryKey = undefined;
      }
      newPrimaryKeys.push([row, primaryKeyColIdx, newPrimaryKey]);
    });
    hot.setDataAtCell(newPrimaryKeys);
  };

  const setDataFromSource = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    setLoading(true);
    const autoloadColumn = columns.find(
      (col) => col.id === seedData?.metadata?.autoload_column
    );
    if (!autoloadColumn) {
      message.error({
        content: 'No autoload column found',
        key: 'autoload',
      });
      setLoading(false);
      return;
    }
    const source = autoloadColumn.source_table;
    const autoloadPrimaryKey = autoloadColumn.name;
    const rows = autocompleteOptions?.[source];

    if (!rows || !rows.length) {
      message.error({
        content: !rows?.length
          ? `No data found in ${source}`
          : 'Primary key not found',
        key: 'autoload',
      });
      setLoading(false);
      return;
    }
    filterByStatus('all');

    const autoloadPrimaryKeyCols = getPrimaryKeyCols([autoloadPrimaryKey]);
    const keyCols = autoloadPrimaryKeyCols.map((col) =>
      columns.find((c) => c.name === col)
    );

    // TODO: check if the source_column name is different from the column name to avoid unnecessary renaming
    const newDataWithSourceColumnRenamed = rows.map((row) => {
      const newRow = {};
      for (const col of keyCols) {
        newRow[col.name] = row[col.source_column];
      }
      return newRow;
    });

    const data = hot.getSourceData();

    const hotDataWithAutoloadPrimaryKeys = addPrimaryKeys(
      data,
      autoloadPrimaryKeyCols,
      '___autoloadPrimaryKey'
    );
    const newDataWithAutoloadPrimaryKeys = addPrimaryKeys(
      newDataWithSourceColumnRenamed,
      autoloadPrimaryKeyCols,
      '___autoloadPrimaryKey'
    );

    const newTableData = [];
    for (const row of newDataWithAutoloadPrimaryKeys) {
      const newRow = {};
      const match = hotDataWithAutoloadPrimaryKeys.find(
        (r) => r.___autoloadPrimaryKey === row.___autoloadPrimaryKey
      );
      for (const col of columns) {
        if (!col.name.includes('___') && col.name !== '') {
          newRow[col.name] = match ? match[col.name] : row[col.name];
        } else {
          newRow[col.name] = match ? match[col.name] : '';
        }
      }
      newTableData.push(newRow);
    }

    const newTableDataWithPrimaryKeys = addPrimaryKeys(
      newTableData,
      primaryKeyCols,
      '___primaryKey'
    );

    // add old data that is not in the new data to the new data
    for (const row of hotDataWithAutoloadPrimaryKeys) {
      if (
        !newDataWithAutoloadPrimaryKeys.find(
          (r) => r.___autoloadPrimaryKey === row.___autoloadPrimaryKey
        )
      ) {
        newTableDataWithPrimaryKeys.push(row);
      }
    }

    // check if the new data is different from the old data
    if (isEqual(newTableDataWithPrimaryKeys, data)) {
      message.info({
        content: 'No new entries found',
        key: 'autoload',
        duration: 2,
      });
      setLoading(false);
      return;
    }

    hot.batch(() => {
      hot.updateSettings({
        data: newTableDataWithPrimaryKeys,
      });
    });
    setTimeout(() => setLoading(false), 1000);
  };

  const filterByStatus = (status) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    setSelectedStatus(status);
    const filtersPlugin = hot.getPlugin('filters');
    filtersPlugin.clearConditions(0);
    if (status !== 'all') {
      filtersPlugin.addCondition(0, 'contains', [status]);
    }
    filtersPlugin.filter();
  };

  const handleSave = (version) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot || loadingData) return;
    const data = hot.getSourceData();

    const dataToSave = [];
    const colsToSave = [];
    seedData?.metadata.columns?.forEach((col) => {
      colsToSave.push(col.name);
      if (col.type === 'autocomplete' && col.linked_columns?.length) {
        col.linked_columns.forEach((linkedCol) => {
          colsToSave.push(linkedCol.name);
        });
      }
    });

    if (!colsToSave) {
      message.error({
        content: 'Please save the configuration for this seed first',
        key: 'save',
      });
      return;
    }
    data?.forEach((row) => {
      const newRow = {};
      for (const col of colsToSave) {
        if (version === 'discard') {
          newRow[col] = row[`${col}___original`];
        } else {
          newRow[col] = row[col];
        }
      }
      // if the row values are all empty or undefined, don't save the row
      if (Object.values(newRow).join('') !== '') {
        dataToSave.push(newRow);
      }
    });

    const originalData =
      version === 'final' ? seedData?.data : seedData?.draft_data;

    filterByStatus('all');

    // check if dataToSave is different from the original data
    if (isEqual(dataToSave, originalData)) {
      message.info({
        content: 'No changes to save',
        key: 'save',
        duration: 2,
      });
      return;
    }

    const newData = {
      seed_name: `${seedId}${version === 'final' ? '' : '_draft'}`,
      data: dataToSave,
    };
    saveSeedData(newData)
      .unwrap()
      .then(() => {
        if (!hot) return;
        message.success({
          content: version === 'final' ? 'Saved' : 'Draft saved',
          key: 'save',
          duration: 2,
        });
      });
  };

  const clearFilters = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    setSelectedStatus('all');
    const filtersPlugin = hot.getPlugin('filters');
    const sortPlugin = hot.getPlugin('columnSorting');
    sortPlugin.clearSort();
    filtersPlugin.clearConditions();
    filtersPlugin.filter();
  };

  const getDraftData = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    const data = hot.getSourceData();
    const currentData = data.map((row) => {
      const newRow = {};
      for (const col of columns) {
        if (!col.name || col.name.includes('___')) continue;
        newRow[col.name] = row[col.name];
      }
      return newRow;
    });
    return currentData;
  };

  const onAfterChange = (changes, source) => {
    /*
     * Check if the data has been edited, and if so, set isDirty to true
     * We need to compare the new data with initialData
     */
    if (source === 'updateData') return;

    const draftData = getDraftData();
    dispatch(
      updateFormField({
        id: `source_manager_${seedId}`,
        field: 'table_data',
        value: _.cloneDeep(draftData),
      })
    );
  };

  return (
    <div>
      <Alert
        description={
          <div>
            <p>How to use this page:</p>
            <ul>
              <li>Double click or start typing to open a cell dropdown.</li>
              {seedData?.metadata?.enable_autoload && (
                <li>
                  Click <b>Load New Entries</b> to load any new entries from the
                  source table. Entries that already appear in the table will
                  not be duplicated or overwritten.
                </li>
              )}
              <li>
                Deleting data:
                <ul>
                  <li>
                    Selecting a row/rows and hitting <b>Delete</b> will remove
                    the data from editable columns only.
                  </li>
                  <li>
                    Selecting a row/rows, right clicking, and selecting{' '}
                    <b>Remove Row/s</b> will remove the entire row.
                  </li>
                </ul>
              </li>
              <li>
                Click <b>Check Statuses</b> to review changes before saving.
              </li>
              <li>
                Hit <b>Save Draft</b> before clicking <b>Finalize Changes</b>.
              </li>
            </ul>
          </div>
        }
        type="success"
        style={{marginBottom: '20px'}}
      />
      {seedData?.metadata?.enable_autoload && (
        <Button
          onClick={setDataFromSource}
          disabled={loadingData || loadingOptions || loading}
          type="primary"
          style={{marginBottom: '20px'}}
        >
          Load New Entries
        </Button>
      )}
      <Spin spinning={loadingData || loadingOptions || loading}>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          <Segmented
            options={[
              {label: 'All', value: 'all'},
              ...Object.keys(statusCounts).map((status) => ({
                label: `${statuses[status]?.label} (${statusCounts[status]})`,
                value: status,
                disabled: statusCounts[status] === 0,
              })),
            ]}
            defaultValue="all"
            value={selectedStatus}
            onChange={filterByStatus}
          />
          <Button
            onClick={getRowStatuses}
            type="primary"
            // loading={calculatingStatuses}
          >
            Check Statuses
          </Button>
          <div style={{flex: 1}} />
          <Button onClick={clearFilters} type="primary">
            Clear Filters
          </Button>
        </div>
        <div style={{height: '75vh', marginTop: '5px'}}>
          <HotTable ref={hotRef} settings={settings} />
        </div>
      </Spin>
      <div className="flex-row" style={{margin: '10px 0'}}>
        <Button
          type="primary"
          onClick={() => handleSave('draft')}
          disabled={loadingData || !isDirty} // disable if there are no changes to save
        >
          Save Draft
        </Button>
        <Popconfirm
          title={
            <div>
              Are you sure you want to discard this draft?
              <br />
              This cannot be undone.
            </div>
          }
          disabled={loadingData || (!isDirty && !isDiff)} // disable if there are no diffs and there are no changes to save
          onConfirm={() => handleSave('discard')}
          okText="Yes"
          cancelText="No"
          okButtonProps={{danger: true, type: 'default'}}
          cancelButtonProps={{type: 'primary'}}
        >
          <Button disabled={loadingData || (!isDirty && !isDiff)}>
            Discard Draft
          </Button>
        </Popconfirm>
        <span style={{flex: 1}} />
        <Popconfirm
          title={
            <div>
              Are you sure you want to save this version?
              <br />
              This cannot be undone.
            </div>
          }
          disabled={
            loadingData || !isDiff || !seedData?.metadata?.published || isDirty
          } // disable if there are no diffs, or the seed is not published, or there are changes to save
          onConfirm={() => handleSave('final')}
          okText="Yes"
          cancelText="No"
          okButtonProps={{danger: true, type: 'default'}}
          cancelButtonProps={{type: 'primary'}}
        >
          <Button
            disabled={
              loadingData ||
              !isDiff ||
              !seedData?.metadata?.published ||
              isDirty
            }
          >
            Finalize Changes
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default TableEditor;
