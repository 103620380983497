import Auth from 'Auth';
import {Button, Comment, Drawer, Form, Input} from 'antd';
import {useLazyGetSearchTokenQuery} from 'api/searchSlice';
import axios from 'axios';
import PageForm from 'components/dataPortal/PageForm';
import Artifacts from 'components/search/Artifacts';
import ChatMessage from 'components/search/ChatMessage';
import React, {useEffect, useRef, useState} from 'react';
import {LOCALE, TRACK_API_BASE_URL} from 'utils/consts';
import {handleApiError} from 'utils/errorHandler';
import {generateUniqueId} from 'utils/helpers';

const xhrAxios = axios.create({
  adapter: 'fetch',
});

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [infoItem, setInfoItem] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [artifacts, setArtifacts] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [localPage, setLocalPage] = useState();
  const [localWidgets, setLocalWidgets] = useState([]);

  const editorMode = Auth.permissions.access_to_data_portal_editor;

  const [form] = Form.useForm();

  const containerRef = useRef(null);
  const newMessageRef = useRef(null);

  const [getToken, {data: token}] = useLazyGetSearchTokenQuery();

  useEffect(() => {
    getToken();
  }, []);

  const scrollToBottom = () => {
    const element = containerRef.current;
    const newMessageElement = newMessageRef.current;
    if (element) {
      let height = 0;
      if (newMessageElement) {
        // get the height of the new message element, since it is not yet in the DOM
        height = newMessageElement.offsetHeight;
      }
      element.scrollTo({
        top: element.scrollHeight + height,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setMessageText(newMessageRef?.current?.innerText);
    });

    if (newMessageRef.current) {
      observer.observe(newMessageRef.current, {
        childList: true, // for text changes
        subtree: true, // if there are nested elements
        characterData: true, // for character data changes
      });
    }

    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, []);

  useEffect(() => {
    // scroll to bottom when messages change
    scrollToBottom();
  }, [messages, messageText]);

  const streamResponse = async (decoder, reader, newMessages) => {
    const newMessage = {
      author: 'Bot',
      id: generateUniqueId(messages.map((m) => m.id)),
      time: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
      content: '', // We'll move the content from the ref later
    };
    newMessageRef.current.innerText = 'Processing...';

    let buffer = '';
    let receivedText = '';

    while (true) {
      const {done, value} = await reader.read();
      if (done) break;

      // Decode the incoming chunk
      const decoded = decoder.decode(value, {stream: true});
      // Add the decoded chunk to the buffer
      buffer += decoded;

      let boundary = 0;
      while ((boundary = buffer.indexOf('\n')) !== -1) {
        const jsonString = buffer.slice(0, boundary).trim();
        buffer = buffer.slice(boundary + 1);

        if (jsonString) {
          try {
            // Parse the JSON string
            const decodedJSON = JSON.parse(jsonString);

            // Update the receivedText with the content
            if (decodedJSON.type === 'ai') {
              receivedText += decodedJSON.content;
              newMessageRef.current.innerText = receivedText;
            } else if (decodedJSON.type === 'system') {
              setInfoItem(decodedJSON);
            }
          } catch (error) {
            console.error('Failed to parse JSON:', error);
          }
        }
      }
    }

    // Handle any remaining buffered data that didn't end with a newline
    if (buffer) {
      try {
        if (!buffer.trim()) return;

        const decodedJSON = JSON.parse(buffer.trim());
        receivedText += decodedJSON.content;
        newMessageRef.current.innerText = receivedText;
      } catch (error) {
        console.error('Failed to parse remaining JSON:', error);
      }
    }

    // extract any artifacts from the message and add them to the artifacts list
    const parsedResponse = parseResponse(receivedText, newMessage, newMessages);

    newMessages.push(parsedResponse);
    setMessages(newMessages);

    // unset the new message ref
    newMessageRef.current.innerText = '';
    setInfoItem(null);

    // refocus on the input field
    form.getFieldInstance('message').focus();
  };

  const parseResponse = (receivedText, newMessage) => {
    const preambleRegex = /[\S\s]*?(?=(\n\[\d\]))/;
    const contentRegex = /(?<=\n\n\+{13}\n\n)([\s\S]*)/;
    const footnoteNumberRegex = /\[\d\]/;
    const artifactRegex =
      /\[\d+\]\s\-\s([^\|]+\s\|\s){3}\(\d+\):[\S\s]*?(?=\n(\[\d+\])|(\+{13}))/g;

    const newMessageArtifacts = [];

    let match;
    while ((match = artifactRegex.exec(receivedText)) !== null) {
      const footnoteNumber = match[0].match(footnoteNumberRegex)?.[0];
      const artifactParts = match[0].split(' | ');
      const sourceLink = artifactParts[0].split(' - ')[1];
      const sourceName = artifactParts[1];
      const sourceContent = artifactParts[3];
      const sourceId = sourceLink.split('/')[5] ?? sourceLink.split('/')[1];
      const sourceType = sourceLink.split('/')[3] ?? sourceLink.split('/')[0];

      const source =
        sourceType === 'document' ? `${sourceLink}/preview` : sourceLink;

      const artifact = {
        id: sourceId,
        source,
        name: sourceName,
        footnoteNumber,
        content: sourceContent,
        rawText: match[0],
      };
      newMessageArtifacts.push(artifact);
    }

    // push the artifact to the artifacts list if the id is not already in the list
    const newArtifacts = [...artifacts];
    for (const artifact of newMessageArtifacts) {
      if (!newArtifacts.find((a) => a.id === artifact.id)) {
        newArtifacts.push(artifact);
      }
    }
    setArtifacts(newArtifacts);

    newMessage.preamble = receivedText.match(preambleRegex)?.[0];
    newMessage.artifacts = newMessageArtifacts;
    newMessage.content = receivedText.match(contentRegex)?.[0] || receivedText;
    newMessage.rawText = receivedText;

    return newMessage;
  };

  const sendMessage = (e) => {
    const yourMessageIndex = messages.length;
    const newMessages = [...messages];
    newMessages[yourMessageIndex] = {
      author: 'You',
      content: e.message,
      id: generateUniqueId(messages.map((m) => m.id)),
      time: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
    };
    setInfoItem({
      type: 'system',
      content: 'Processing...',
    });
    setMessages(newMessages);
    form.resetFields();

    xhrAxios
      .put(
        `${TRACK_API_BASE_URL}/search/`,
        {
          message: e.message,
          session: token,
        },
        {
          responseType: 'stream',
          headers: {
            Authorization: Auth.getToken(),
          },
        }
      )
      .then((response) => {
        const stream = response.data;
        const reader = stream.getReader();
        const decoder = new TextDecoder();
        streamResponse(decoder, reader, newMessages);
      })
      .catch((error) => {
        console.error('Failed to send message:', error);
        setInfoItem({
          type: 'system',
          content: 'Failed to send message',
        });
        handleApiError(error);
      });
  };

  const openPageForm = (message) => {
    const messageText = [message];
    const messageArtifacts = message.artifacts;
    setupNewPageData(messageText, messageArtifacts);
  };

  const setupNewPageData = (newPageMessages, newPageArtifacts) => {
    const newPage = {
      title: 'New Page',
      slug: '',
      published: false,
      widgets: [],
    };
    for (const message of newPageMessages) {
      newPage.widgets.push({
        type: 'text',
        content: message.content,
      });
    }
    for (const artifact of newPageArtifacts) {
      newPage.widgets.push({
        type: 'google',
        source: artifact.source,
      });
    }
    setLocalPage(newPage);
    setLocalWidgets(newPage.widgets);
    setDrawerVisible(true);
  };

  return (
    <div className="flex-row" style={{height: '80vh'}}>
      <div className="flex-column" style={{height: '80vh', flex: 1}}>
        <div
          style={{flex: 1, overflowY: 'auto'}}
          className="flex-column"
          ref={containerRef}
        >
          {messages.map((message) => (
            <ChatMessage
              key={message.id}
              message={message}
              openPageForm={() => openPageForm(message)}
            />
          ))}
          <Comment
            style={{
              alignContent: 'start',
              alignSelf: 'flex-start',
              backgroundColor: '#f0f0f0',
              border: '1px solid var(--primary-color)',
              borderRadius: '5px',
              display: newMessageRef?.current?.innerText ? 'block' : 'none',
              margin: '0 20px 0 0',
              padding: '0 10px',
              width: 'fit-content',
            }}
            author="Bot"
            content={<p ref={newMessageRef} />}
            datetime={new Date().toLocaleTimeString(LOCALE, {
              timeStyle: 'short',
            })}
          />
          <div style={{flex: 1}} />
        </div>
        {infoItem && <div>{infoItem.content}</div>}
        {editorMode && (
          <div>
            <Button
              onClick={() => setupNewPageData(messages, artifacts)}
              style={{margin: '10px 0', float: 'right'}}
            >
              Create Page
            </Button>
          </div>
        )}
        <Form
          className="flex-row"
          form={form}
          name="control-hooks"
          onFinish={sendMessage}
          style={{width: '100%', margin: '10px 0'}}
        >
          <Form.Item
            name="message"
            rules={[{required: true, message: 'Please type a message'}]}
            style={{flex: 1, marginBottom: 0}}
          >
            <Input placeholder="Type a message..." size="large" />
          </Form.Item>

          <Form.Item style={{marginBottom: 0}}>
            <Button type="primary" htmlType="submit" size="large">
              Send
            </Button>
          </Form.Item>
          <Form.Item style={{marginBottom: 0}}>
            <Button
              type="link"
              size="large"
              onClick={() => {
                setMessages([]);
                setArtifacts([]);
                getToken();
              }}
            >
              New Chat
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Artifacts artifacts={artifacts} />
      <Drawer
        closable={true}
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          position: 'fixed',
          overflowY: 'auto',
          minHeight: 'calc(100% - 64px)',
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        }}
        title="Create Page"
        width={600}
      >
        <PageForm
          pageSlug=""
          localPage={localPage}
          setLocalPage={setLocalPage}
          localWidgets={localWidgets}
          setLocalWidgets={setLocalWidgets}
        />
      </Drawer>
    </div>
  );
};

export default Chat;
