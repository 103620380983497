import {Modal} from 'antd';
import React from 'react';
import ReactRouterPrompt from 'react-router-prompt';

const PageLeaveWarning = ({message, isDirty}) => {
  return (
    <ReactRouterPrompt when={isDirty} message={message}>
      {({isActive, onConfirm, onCancel}) => (
        <Modal open={isActive} onOk={onConfirm} onCancel={onCancel}>
          {message}
        </Modal>
      )}
    </ReactRouterPrompt>
  );
};

export default PageLeaveWarning;
