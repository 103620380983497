import Auth from 'Auth';
import {emptySplitApi} from 'api/apiSlice';
import axios from 'axios';
import {TRACK_API_BASE_URL} from 'utils/consts';
import {handleRTKQError} from 'utils/errorHandler';

// Original search token endpoint
const searchSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearchToken: builder.query({
      query: () => 'search/',
      transformErrorResponse: handleRTKQError,
    }),
  }),
});

// Stream handling utilities
export class StreamParser {
  constructor() {
    this.buffer = '';
    this.receivedText = '';
  }

  async parseStream(stream, onChunk) {
    const reader = stream.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const {done, value} = await reader.read();
      if (done) break;

      this.buffer += decoder.decode(value, {stream: true});

      let boundary = 0;
      while ((boundary = this.buffer.indexOf('\n')) !== -1) {
        const jsonString = this.buffer.slice(0, boundary).trim();
        this.buffer = this.buffer.slice(boundary + 1);

        if (jsonString) {
          try {
            const decodedJSON = JSON.parse(jsonString);
            await onChunk(decodedJSON);
          } catch (error) {
            console.error('Failed to parse JSON:', error);
          }
        }
      }
    }

    // Handle remaining buffer
    if (this.buffer.trim()) {
      try {
        const decodedJSON = JSON.parse(this.buffer.trim());
        await onChunk(decodedJSON);
      } catch (error) {
        console.error('Failed to parse remaining JSON:', error);
      }
    }
  }
}

// API utilities
const xhrAxios = axios.create({
  adapter: 'fetch',
});

export const searchPortalApi = {
  makeStreamRequest: async (endpoint, body) => {
    try {
      const response = await xhrAxios.put(
        `${TRACK_API_BASE_URL}${endpoint}`,
        body,
        {
          responseType: 'stream',
          headers: {
            Authorization: Auth.getToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Failed to make stream request to ${endpoint}:`, error);
      throw error;
    }
  },

  sendMessage: (message, session, is_first) => {
    return searchPortalApi.makeStreamRequest('/search/', {
      message,
      session,
      is_first,
    });
  },

  getSuggestions: (session) => {
    return searchPortalApi.makeStreamRequest('/search/suggest/', {session});
  },
};

export const {useLazyGetSearchTokenQuery} = searchSlice;
