import moment from 'moment';
import {ALPHABET, CROSS_GRADE} from 'utils/consts';

/**
 * Returns the pluralized version of the grade.
 * @param {number|string} grade - The grade to pluralize.
 * @returns {string} The pluralized grade.
 */
export function pluralizeGrade(grade) {
  let gradeStr = grade.toString();
  let output = '';
  if (gradeStr === '0') {
    output = 'Kth';
  } else if (gradeStr === '1') {
    output = '1st';
  } else if (gradeStr === '2') {
    output = '2nd';
  } else if (gradeStr === '3') {
    output = '3rd';
  } else if (
    gradeStr === 'Cross Grade' ||
    gradeStr === CROSS_GRADE.toString()
  ) {
    output = 'Cross';
  } else {
    output = grade + 'th';
  }
  return output;
}

/**
 * Converts a timestamp to a date string in the format of MM/DD/YYYY.
 * @param {string} ts - The timestamp to convert.
 * @returns {string} The formatted date string.
 */
export function tsToNy(ts) {
  const job_start = new Date(Date.parse(ts));
  const usaTime = job_start.toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  return usaTime;
}

/**
 * Returns the display grade of the grade number.
 * @param {number|string} gradenum - The grade number.
 * @returns {string} The display grade.
 */
export function getDisplayGrade(gradenum) {
  if (gradenum === undefined) {
    return '';
  }
  if (gradenum.toString() === CROSS_GRADE.toString()) {
    return 'Cross Grade';
  } else if (gradenum === 'K' || gradenum.toString() === '0') {
    return 'Kindergarten';
  }
  return 'Grade ' + gradenum;
}

/**
 * Returns the percentage of each number in the array.
 * @param {number[]} nums - The array of numbers.
 * @returns {string[]} The array of percentages.
 */
export const getPercentages = (nums) => {
  const sum = nums.reduce((a, b) => a + b, 0);
  return nums.map((num) => ((num / sum) * 100).toFixed(2));
};

/**
 * Calculates the gradespan. If starting or ending don't exist, returns [].
 * @param {number} starting - The starting grade.
 * @param {number} ending - The ending grade.
 * @param {boolean} crossGradeEnabled - Whether to include cross grade.
 * @returns {Array<string|number>} The gradespan array.
 */
export const calculateGradespan = (starting, ending, crossGradeEnabled) => {
  if (starting === null || ending === null) return [];
  if (starting > ending) return [];
  const gradespan = [];
  for (let i = starting; i <= ending; i++) {
    gradespan.push(i);
  }
  if (crossGradeEnabled) {
    gradespan.push(CROSS_GRADE);
  }
  return gradespan;
};

/**
 * Returns number if it is between min and max, otherwise returns min or max.
 * @param {number} num - The number to clamp.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} The clamped number.
 */
export function clamp(num, min, max) {
  if (isNaN(num)) return min;
  return num <= min ? min : num >= max ? max : num;
}

/**
 * Compares two objects by their length.
 * @param {Object} a - The first object.
 * @param {Object} b - The second object.
 * @returns {number} 1 if a is longer than b, -1 if b is longer than a, 0 if equal.
 */
export const compareByLength = (a, b) => {
  if (a.data[a.data.length - 1].y > b.data[b.data.length - 1].y) return 1;
  if (a.data[a.data.length - 1].y < b.data[b.data.length - 1].y) return -1;
  return 0;
};

/**
 * Parses a date and returns a string with the operator and date.
 * @param {string} date - The date to parse.
 * @param {string} [operator] - The operator to use.
 * @param {string} [format='YYYY-MM-DD'] - The date format.
 * @returns {string} The parsed date string.
 */
export const parseDate = (date, operator, format = 'YYYY-MM-DD') => {
  let value = date;
  if (
    !date ||
    date === 'null' ||
    date === 'undefined' ||
    date === 'NaN' ||
    date === 'Invalid date'
  ) {
    return operator ?? '';
  }
  if (operator === 'BETWEEN') {
    const dates = value.toString().split(',');
    try {
      const parsedDates = dates
        .map((element) => {
          if (
            !element ||
            element === 'null' ||
            element === 'undefined' ||
            element === 'NaN' ||
            element === 'Invalid date'
          ) {
            return '';
          } else {
            return moment(element, format).format(format);
          }
        })
        .join(' - ');
      const returnVal = `BETWEEN ${parsedDates}`;
      return returnVal;
    } catch (e) {
      return 'BETWEEN';
    }
  } else {
    const oper = operator ?? '';
    try {
      value = moment(value, format).format(format);
    } catch (e) {
      return oper;
    }
    return `${oper} ${value}`;
  }
};

/**
 * Parses a number and returns a string with the operator and number.
 * @param {number|string} number - The number to parse.
 * @param {string} [operator] - The operator to use.
 * @returns {string} The parsed number string.
 */
export const parseNumber = (number, operator) => {
  if (
    (!number && number !== 0) ||
    number === 'null' ||
    number === 'undefined' ||
    number === 'NaN'
  ) {
    return operator ?? '';
  }
  let value = number;
  if (operator === 'BETWEEN') {
    const numbers = value.toString().split(',');
    try {
      const parsedNumbers = numbers
        .map((element) => {
          if (
            (!element && number !== 0) ||
            element === 'null' ||
            element === 'undefined' ||
            element === 'NaN'
          ) {
            return '';
          } else {
            return parseInt(element);
          }
        })
        .join(' - ');
      const returnVal = `BETWEEN ${parsedNumbers}`;
      return returnVal;
    } catch (e) {
      return 'BETWEEN';
    }
  } else {
    const oper = operator ?? '';
    try {
      value = parseInt(number);
    } catch (e) {
      return oper;
    }
    if (isNaN(value)) {
      return oper;
    }
    return `${oper} ${value}`;
  }
};

/**
 * Generates a unique id that is not in the existing array.
 * @param {Array<string|number>} [existing=[]] - The array of existing ids.
 * @param {string} [idType='string'] - The type of id to generate ('string' or 'number').
 * @returns {string|number} The generated unique id.
 */
export const generateUniqueId = (existing = [], idType = 'string') => {
  // Generate a random 5 digit number.
  const id =
    idType === 'string'
      ? Math.random().toString(36).substring(2, 9)
      : Math.floor(Math.random() * 90000) + 10000;
  while (existing.includes(id)) {
    generateUniqueId(existing);
  }
  return id;
};

/**
 * Returns the letter at the specified index in the alphabet.
 * @param {number} index - The index of the letter.
 * @returns {string} The letter at the specified index.
 */
export const getLetterByIndex = (index) => {
  if (index < 0 || index >= ALPHABET.length) {
    return '';
  }
  return ALPHABET[index];
};

/**
 * Strips HTML tags from a string, allowing only specified tags.
 * @param {string} input - The input string.
 * @param {string} [allowed='<a><b><strong><i><em><p><br><ul><ol><li><span><div>'] - The allowed tags.
 * @returns {string} The string with HTML tags stripped.
 */
export const stripTags = (
  input,
  allowed = '<a><b><strong><i><em><p><br><ul><ol><li><span><div>'
) => {
  if (!input) return '';
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const output = input.toString().replace(tags, ($0, $1) => {
    return allowed?.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
  });
  return output;
};

/**
 * Extracts text content from an HTML string.
 * @param {string} htmlString - The HTML string.
 * @returns {string[]} The array of text content.
 */
export const extractHTMLTextContent = (htmlString) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const children = tempDiv.childNodes;
  const strArray = [];
  for (let i = 0; i < children.length; i++) {
    strArray.push(children[i].textContent);
  }
  return strArray;
};

/**
 * Converts a string to a float, and returns the number if it is a valid number.
 * Otherwise, returns an empty string.
 * @param {string} str - The string to convert.
 * @returns {number|string} The converted float or an empty string.
 */
export const stringToFloat = (str) => {
  if (str === '') {
    return '';
  }
  const num = parseFloat(str);
  return isNaN(num) ? '' : num;
};

/**
 * Decides on the color of the text based on the background color.
 * @param {string} hexcolor - The background color in hex format.
 * @returns {string} The text color.
 */
export const setContrast = (hexcolor) => {
  if (!hexcolor || !hexcolor.match(/^#[0-9A-F]{6,8}$/i)) {
    return '#000000d9';
  }
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000d9' : 'white';
};

/**
 * Generates a mailto link with the specified email, subject, and message.
 * @param {string} email - The email address.
 * @param {string} subject - The email subject.
 * @param {string} message - The email message.
 * @returns {string} The mailto link.
 */
export const setMailtoMessage = (email, subject, message) => {
  return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
};

/**
 * Returns the weighted width of a string based on the character types.
 * @param {string} str - The string to calculate the width of.
 * @returns {number} The weighted width of the string.
 */
export const getWeightedWidth = (str, base = 250) => {
  return (
    str?.split('').reduce((acc, char) => {
      let width = 8; // Average width of a character
      if ('iIl|.,:;!()-'.includes(char)) width = 4; // Narrow
      // All caps are wide chars
      if (char === char.toUpperCase() || 'wm_'.includes(char)) width = 12;
      return acc + width;
    }, 0) || base
  );
};
