import {Form, Spin, Table, message} from 'antd';
import {
  useFetchTeacherAssignmentsQuery,
  useSetTeacherAssignmentsMutation,
} from 'api/esoySlice';
import SelectOptions from 'components/genericComponents/SelectOptions';
import React, {useState} from 'react';

const EditableContext = React.createContext(null);
function EditableRow({index, ...props}) {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}
function TeacherSelectCell({
  editable,
  children,
  dataIndex,
  disabled,
  record,
  grade,
  siteId,
  handleCheckAllAssigned,
  assignments,
  ...restProps
}) {
  const {data} = useFetchTeacherAssignmentsQuery(siteId, {
    skip: !siteId,
  });
  const [setTeacherAssignments] = useSetTeacherAssignmentsMutation();

  const [selectedOption, setSelectedOption] = useState(record?.[dataIndex]);

  const onTeacherChange = (newTeacherId, homeroomId) => {
    setSelectedOption(newTeacherId);
    const assignment = assignments.find(
      (e) =>
        e.course_number === record.courseId &&
        e.homeroom_id.toString() === homeroomId
    );

    const assignmentToPost = {
      site_id: siteId,
      grade,
      course_number: record.courseId,
      homeroom_id: homeroomId,
      teacher_id: newTeacherId,
      assignment_id: assignment ? assignment.assignment_id : null,
    };

    setTeacherAssignments({siteId, assignment: assignmentToPost})
      .unwrap()
      .then(() => {
        message.success('Teacher assigned.');
        handleCheckAllAssigned();
      })
      .catch(() => {
        message.error('Error assigning teacher.');
      });
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <SelectOptions
        options={data?.sortedTeachers?.map((teacher) => ({
          value: teacher.teacher_id,
          label: teacher.teacher_name,
        }))}
        onChange={(value) => onTeacherChange(value, dataIndex)}
        placeholder="Select a teacher"
        disabled={disabled}
        style={{minWidth: 300, margin: 0, width: '100%'}}
        size="middle"
        value={selectedOption}
      />
    );
  }
  return <td {...restProps}>{childNode}</td>;
}

const TeacherAssignTable = ({
  disabled,
  grade,
  siteId,
  handleCheckAllAssigned,
}) => {
  const {data, isLoading} = useFetchTeacherAssignmentsQuery(siteId, {
    refetchOnMountOrArgChange: true,
  });

  const components = {
    body: {
      row: EditableRow,
      cell: TeacherSelectCell,
    },
  };

  const getColumns = () => {
    if (!data) return [];
    const columns = [
      {
        title: 'Course',
        dataIndex: 'course',
        width: '150px',
        align: 'left',
        editable: false,
      },
      ...data.gradesData?.[grade].homerooms.map((homeroom) => ({
        title: Object.values(homeroom)[0],
        dataIndex: Object.keys(homeroom)[0],
        width: '200px',
        editable: true,
        key: Object.keys(homeroom)[0],
      })),
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          grade,
          siteId,
          key: col.dataIndex,
          disabled,
          handleCheckAllAssigned,
          assignments: data?.gradesData?.[grade]?.assignments || [],
        }),
      };
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={data?.gradesData?.[grade]?.courses}
        columns={getColumns()}
        pagination={false}
        scroll={{x: '100%'}}
      />
    </Spin>
  );
};

export default TeacherAssignTable;
