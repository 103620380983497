import Auth from 'Auth';
import {useFetchSeedsQuery} from 'api/seedsSlice';
import Catalog from 'components/genericComponents/Catalog';
import React from 'react';
import {useLocation} from 'react-router-dom';

const SeedsOverviewPage = () => {
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;

  const {data: seeds, isLoading: isSeedsLoading} = useFetchSeedsQuery(
    editorMode ? 'latest' : 'published'
  );

  const extraTags = [
    {
      color: 'red',
      id: 'draft',
      label: 'Draft',
      name: 'Draft',
      value: 'draft',
    },
    {
      color: 'magenta',
      id: 'unpublished_data',
      label: 'Unpublished Data Changes',
      name: 'Unpublished Data Changes',
      value: 'unpublished_data_changes',
    },
    {
      color: 'orange',
      id: 'unpublished_schema_changes',
      label: 'Unpublished Schema Changes',
      name: 'Unpublished Schema Changes',
      value: 'unpublished_schema_changes',
    },
  ];

  return (
    <div>
      <h1>Track-Managed Sources</h1>
      <Catalog
        editorPermission={Auth.permissions.access_to_source_manager_editor}
        extraTags={extraTags}
        loading={isSeedsLoading}
        pages={seeds}
        path="source_manager"
      />
    </div>
  );
};

export default SeedsOverviewPage;
