import TrackLayout from 'Layout';
import ProtectedRoute from 'ProtectedRoute';
import AuditsPage from 'pages/AuditsPage';
import LoginPage from 'pages/AuthLoginPage';
import DataPortalOverviewPage from 'pages/DataPortalOverviewPage';
import DataPortalPage from 'pages/DataPortalPage';
import DataReportOverviewPage from 'pages/DataReportOverviewPage';
import DataReportPage from 'pages/DataReportPage';
import EsoyITSupportPage from 'pages/EsoyITSupportPage';
import EsoyOverview from 'pages/EsoyOverviewPage';
import HSSchedulePage from 'pages/HSSchedulePage';
import TwoFactorAuth from 'pages/MFALogin';
import NotFoundPage from 'pages/NotFoundPage';
import PersonMappingPage from 'pages/PersonMappingPage';
import SearchPage from 'pages/SearchPage';
import SeedChangesPage from 'pages/SeedChangesPage';
import SeedEditorPage from 'pages/SeedEditorPage';
import SeedsOverviewPage from 'pages/SeedsOverviewPage';
import StaffRolesITSupportPage from 'pages/StaffRolesITSupportPage';
import StaffRolesPage from 'pages/StaffRolesPage';
import TagsAndCategoriesPage from 'pages/TagsAndCategoriesPage';
import TrackHome from 'pages/TrackHome';
import UCPersonDetailsPage from 'pages/UCPersonDetailsPage';
import UCPersonPage from 'pages/UCPersonPage';
import React from 'react';
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom';
import {handleApiError} from 'utils/errorHandler';

function ErrorBoundary() {
  let error = useRouteError();
  handleApiError({
    ...error,
    status: 600,
    data: {
      detail: error?.message,
    },
  });
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <TrackLayout />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: [
          {index: true, element: <TrackHome />},
          {path: '/login', element: <LoginPage />},
          {path: '/login_done', element: <LoginPage />},
          {path: '/login_failed', element: <LoginPage />},
          {path: '/mfa', element: <TwoFactorAuth />},
          {path: '/audits', element: <ProtectedRoute component={AuditsPage} />},
          {
            path: '/audits/:school',
            element: <ProtectedRoute component={AuditsPage} />,
          },
          {
            path: '/audit_rules',
            element: <ProtectedRoute component={AuditsPage} />,
          },
          {
            path: '/audits_overview',
            element: <ProtectedRoute component={AuditsPage} />,
          },
          {
            path: '/audits_issue_overview',
            element: <ProtectedRoute component={AuditsPage} />,
          },
          {
            path: '/audits_school_summary',
            element: <ProtectedRoute component={AuditsPage} />,
          },
          {
            path: '/audits_school_summary/:school',
            element: <ProtectedRoute component={AuditsPage} />,
          },
          {
            path: '/data_report',
            element: <ProtectedRoute component={DataReportOverviewPage} />,
          },
          {
            path: '/data_report/:report',
            element: <ProtectedRoute component={DataReportPage} />,
          },
          {
            path: '/data_report/editor',
            element: <ProtectedRoute component={DataReportOverviewPage} />,
          },
          {
            path: '/data_report/:report/editor',
            element: <ProtectedRoute component={DataReportPage} />,
          },
          {
            path: '/data_report_editor/:report',
            element: <ProtectedRoute component={DataReportPage} />,
          },
          {
            path: '/data_report_editor',
            element: <ProtectedRoute component={DataReportOverviewPage} />,
          },
          {
            path: '/hs_schedule',
            element: <ProtectedRoute component={HSSchedulePage} />,
          },
          {
            path: '/hs_schedule/:school',
            element: <ProtectedRoute component={HSSchedulePage} />,
          },
          {
            path: '/person/mapping',
            element: <ProtectedRoute component={PersonMappingPage} />,
          },
          {
            path: '/person',
            element: <ProtectedRoute component={UCPersonPage} />,
          },
          {
            path: '/person/:ucpid',
            element: <ProtectedRoute component={UCPersonDetailsPage} />,
          },
          {
            path: '/school_setup',
            element: <ProtectedRoute component={EsoyOverview} />,
          },
          {
            path: '/school_setup/:school',
            element: <ProtectedRoute component={EsoyOverview} />,
          },
          {
            path: '/staff_roles',
            element: <ProtectedRoute component={StaffRolesPage} />,
          },
          {
            path: '/staff_roles/:school',
            element: <ProtectedRoute component={StaffRolesPage} />,
          },
          {
            path: '/staff_roles_it_support',
            element: <ProtectedRoute component={StaffRolesITSupportPage} />,
          },
          {
            path: '/esoy_it_support',
            element: <ProtectedRoute component={EsoyITSupportPage} />,
          },
          {
            path: '/source_manager/editor',
            element: <ProtectedRoute component={SeedsOverviewPage} />,
          },
          {
            path: '/source_manager/:source',
            element: <ProtectedRoute component={SeedEditorPage} />,
          },
          {
            path: '/source_manager/:source/editor',
            element: <ProtectedRoute component={SeedEditorPage} />,
          },
          {
            path: '/source_manager',
            element: <ProtectedRoute component={SeedsOverviewPage} />,
          },
          {
            path: '/source_manager_changes',
            element: <ProtectedRoute component={SeedChangesPage} />,
          },
          {
            path: '/data_portal',
            element: <ProtectedRoute component={DataPortalOverviewPage} />,
          },
          {
            path: '/data_portal/editor',
            element: <ProtectedRoute component={DataPortalOverviewPage} />,
          },
          {
            path: '/data_portal/:page',
            element: <ProtectedRoute component={DataPortalPage} />,
          },
          {
            path: '/data_portal/:page/editor',
            element: <ProtectedRoute component={DataPortalPage} />,
          },
          {
            path: '/search',
            element: <ProtectedRoute component={SearchPage} />,
          },
          {
            path: '/search_chat',
            element: <ProtectedRoute component={SearchPage} />,
          },
          {
            path: '/tags_categories',
            element: <ProtectedRoute component={TagsAndCategoriesPage} />,
          },
          {path: '*', element: <NotFoundPage />},
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
