import {createSelector, createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  forms: {},
  originalData: {},
};

export const formSlice = createSlice({
  initialState,
  name: 'form',
  reducers: {
    setFormData(state, action) {
      const {id, data} = action.payload;
      state.forms[id] = data;
      state.originalData[id] = data; // Keep a copy of the original data
    },
    updateFormField(state, action) {
      const {id, field, value} = action.payload;
      if (state.forms[id]) {
        state.forms[id][field] = value;
      }
    },
    updateOriginalField(state, action) {
      const {id, field, value} = action.payload;
      if (state.originalData[id]) {
        state.originalData[id][field] = value;
      }
    },
    resetForm(state, action) {
      const {id} = action.payload;
      state.forms[id] = state.originalData[id]; // Reset to original data
    },
  },
});

export const {setFormData, updateFormField, updateOriginalField, resetForm} =
  formSlice.actions;

export const selectFormState = (state, formId) => state.form.forms[formId];
export const selectOriginalFormData = (state, formId) =>
  state.form.originalData[formId];

export const selectIsFormEdited = createSelector(
  [selectFormState, selectOriginalFormData],
  (formState, originalData) => {
    if (!formState) return false;
    return !_.isEqual(formState, originalData);
  }
);

export default formSlice.reducer;
