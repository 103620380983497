import Auth from 'Auth';
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Popconfirm,
  Spin,
  Switch,
  message,
} from 'antd';
import {
  useDeleteSeedVersionMutation,
  useFetchSeedDataQuery,
  useSetSeedMetaDataMutation,
} from 'api/seedsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import EditorButton from 'components/genericComponents/EditorButton';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import SeedColumnForm from 'components/seeds/SeedColumnForm';
import React, {useEffect, useMemo, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  resetForm,
  selectFormState,
  selectIsFormEdited,
  setFormData,
  updateFormField,
} from 'store/formSlice';
import styled from 'styled-components';
import {generateUniqueId, getLetterByIndex} from 'utils/helpers';

const {Panel} = Collapse;

const SeedColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SeedForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;
  const params = new URLSearchParams(location.search);
  const seedId = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const formState = useSelector((state) =>
    selectFormState(state, `source_manager_${seedId}`)
  );
  const isDirty = useSelector((state) =>
    selectIsFormEdited(state, `source_manager_${seedId}`)
  );

  const [drawerVisible, setDrawerVisible] = useState(false);

  const autoloadOptions = useMemo(() => {
    return formState?.columns
      ?.filter((col) => col.type === 'autocomplete')
      .map((col) => {
        return {
          label: `${col.name} + ${col.linked_columns?.map((c) => c.name).join(' + ')} (${col.source_table})`,
          value: col.col_id,
        };
      });
  }, [formState?.columns]);

  const getAllCols = () => {
    let allCols = [];
    formState?.columns.forEach((col) => {
      allCols.push(col.name);
      if (col.linked_columns?.length) {
        col.linked_columns.forEach((linkedCol) => {
          allCols.push(linkedCol.name);
        });
      }
    });
    return allCols;
  };

  const {data: seedData, isLoading: loadingData} = useFetchSeedDataQuery(
    {
      name: seedId,
      version: params.get('version') || 'latest',
    },
    {
      skip: !seedId,
    }
  );
  const {data: tags} = useFetchTagsQuery();
  const {data: categories} = useFetchCategoriesQuery();

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createOrUpdateTag] = useCreateOrUpdateTagMutation();
  const [deleteSeedVersion] = useDeleteSeedVersionMutation();
  const [setSeedMetaData] = useSetSeedMetaDataMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    if (seedData?.metadata) {
      let columns = [];

      if (seedData?.metadata?.columns?.length) {
        columns = seedData.metadata.columns;
      } else if (seedData?.data?.[0]) {
        columns = Object.keys(seedData.data[0]).map((key) => ({
          col_id: generateUniqueId([], 'int'),
          key,
          linked_columns: [],
          name: key,
          type: 'text',
        }));
      } else if (seedData?.draft_data?.[0]) {
        columns = Object.keys(seedData.draft_data[0]).map((key) => ({
          col_id: generateUniqueId([], 'int'),
          key,
          linked_columns: [],
          name: key,
          type: 'text',
        }));
      }

      const enable_autoload = columns.some(
        (col) => col.type === 'autocomplete'
      );
      const autoload_column = enable_autoload
        ? columns.find((col) => col.type === 'autocomplete')?.col_id
        : null;

      dispatch(
        setFormData({
          id: `source_manager_${seedId}`,
          data: {
            ...seedData.metadata,
            columns,
            enable_autoload,
            autoload_column,
          },
        })
      );
    }
  }, [seedData]);

  const handleAddColumn = () => {
    const newCol = {
      col_id: generateUniqueId(formState.columns, 'int'),
      linked_columns: [],
      name: `Column ${getLetterByIndex(formState.columns.length)}`,
      type: 'text',
    };
    handleSeedChanged('columns', [...formState.columns, newCol]);
  };

  const handleDeleteColumn = (idx) => {
    const newCols = formState.columns.filter((_, i) => i !== idx);
    handleSeedChanged('columns', newCols);
  };

  const handleColumnChanged = (idx, value) => {
    const newCols = formState.columns.map((col, i) => {
      if (i === idx) {
        return value;
      }
      return col;
    });
    handleSeedChanged('columns', newCols);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(formState.columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handleSeedChanged('columns', items);
  };

  const handleSeedChanged = (key, value) => {
    dispatch(
      updateFormField({
        id: `source_manager_${seedId}`,
        field: key,
        value,
      })
    );
  };

  const handleSave = () => {
    setSeedMetaData({
      ...formState,
      seed_name: seedId,
    })
      .unwrap()
      .then(() => {
        navigate(`/source_manager/${seedId}/editor`);
        message.success('Changes saved successfully');
      });
  };

  return (
    <>
      <Drawer
        closable={true}
        extra={
          <Switch
            checked={seedData?.metadata?.published}
            checkedChildren="Unpublish"
            disabled={!seedData?.metadata?.columns?.length}
            loading={loadingData}
            onChange={(checked) =>
              setSeedMetaData({
                version: seedData?.metadata?.version,
                seed_name: seedId,
                published: checked,
              })
            }
            unCheckedChildren="Publish"
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          minHeight: 'calc(100% - 64px)',
          overflowY: 'auto',
          position: 'fixed',
        }}
        title="Source Schema Editor"
        width={600}
      >
        <Spin spinning={loadingData}>
          <div className="flex-column">
            <h3>{formState?.seed_name}</h3>
            <SelectOptions
              label="Category"
              onAdd={(name) => {
                createOrUpdateCategory({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleSeedChanged('category', res);
                  });
              }}
              onChange={(value) =>
                handleSeedChanged('category', {
                  id: value.id,
                  name: value.title,
                  parent: value.parent,
                })
              }
              options={categories?.children}
              tree={true}
              value={formState?.category?.id}
            />
            <SelectOptions
              label="Tags"
              mode="tags"
              multiple
              onAdd={(name) => {
                createOrUpdateTag({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleSeedChanged('tags', [...formState.tags, res]);
                  });
              }}
              onChange={(value) => {
                handleSeedChanged(
                  'tags',
                  tags?.filter((c) => value.includes(c.id))
                );
              }}
              options={tags?.map((c) => ({
                value: c.id,
                label: c.name,
                color: c.color,
              }))}
              style={{width: '100%'}}
              value={formState?.tags?.map((t) => t.id)}
            />
            <div>
              <Alert
                description={
                  <div>
                    Notes:
                    <ul>
                      <li>
                        <b>
                          Column names and column order must match the schema in
                          DBT.
                        </b>
                      </li>
                      <li>
                        Unpublished sources are only visible to editors, and
                        changes cannot be finalized.
                      </li>
                      <li>Published sources are visible to all users.</li>
                    </ul>
                  </div>
                }
                type="info"
                style={{marginBottom: '20px'}}
              />
            </div>
            <h4>Columns</h4>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="columns">
                {(provided) => (
                  <SeedColumnList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {formState?.columns?.map((column, idx) => (
                      <SeedColumnForm
                        column={column}
                        handleDelete={() => handleDeleteColumn(idx)}
                        idx={idx}
                        key={column?.col_id}
                        setColumn={(value) => handleColumnChanged(idx, value)}
                      />
                    ))}
                    {provided.placeholder}
                  </SeedColumnList>
                )}
              </Droppable>
            </DragDropContext>

            <Button
              type="primary"
              onClick={handleAddColumn}
              style={{margin: '10px 0', width: '150px'}}
            >
              Add Column
            </Button>
            <Divider />

            <h4>Unique Identifier</h4>
            <Alert
              description={
                <div>
                  Select all columns that make up a unique entry in this seed.
                  <br />
                  If there are multiple columns, they will be concatenated.
                  <br />
                  If none are selected, all entries will be marked as
                  duplicates.
                </div>
              }
              type="success"
              style={{marginBottom: '20px'}}
            />
            <SelectOptions
              label="Unique Identifier"
              multiple={true}
              onChange={(val) => handleSeedChanged('primary_key', val)}
              options={getAllCols()}
              style={{width: '100%'}}
              value={formState?.primary_key || []}
            />
            <Divider />
            <h4>Autoload</h4>
            <Alert
              description={
                <div>
                  Select the columns you want to autoload from the source table.
                  <br />
                  Autoload is used to populate the seed automatically. It can
                  only be enabled if there are autocomplete columns in the
                  configuration.
                </div>
              }
              type="success"
              style={{marginBottom: '20px'}}
            />
            <Checkbox
              disabled={
                !formState?.columns?.some((col) => col.type === 'autocomplete')
              }
              checked={formState?.enable_autoload}
              onChange={(e) => {
                handleSeedChanged('enable_autoload', e.target.checked);
              }}
            >
              Enable
            </Checkbox>
            {formState?.enable_autoload ? (
              <div className="flex-column" style={{margin: '10px 0'}}>
                <Alert
                  description={
                    <div>
                      If applicable, it is recommended to add the Read Only
                      checkbox attribute for the selected Autoload columns. This
                      will ensure end users can only edit allowed columns.
                    </div>
                  }
                  type="info"
                  style={{marginBottom: '20px'}}
                />
                <SelectOptions
                  label="Columns to Populate"
                  onChange={(val) => {
                    handleSeedChanged('autoload_column', val);
                  }}
                  options={autoloadOptions}
                  required={true}
                  style={{width: '100%'}}
                  value={formState.autoload_column}
                />
              </div>
            ) : null}
            <div className="flex-row" style={{margin: '10px 0'}}>
              <Popconfirm
                cancelButtonProps={{type: 'primary'}}
                cancelText="No"
                disabled={!isDirty}
                okButtonProps={{danger: true, type: 'default'}}
                okText="Yes"
                onConfirm={handleSave}
                title={<div>Are you sure you want to save these changes?</div>}
              >
                <Button
                  type="primary"
                  disabled={!isDirty}
                  loading={loadingData}
                >
                  Save Changes
                </Button>
              </Popconfirm>
              <Popconfirm
                cancelButtonProps={{type: 'primary'}}
                cancelText="No"
                disabled={!isDirty}
                okButtonProps={{danger: true, type: 'default'}}
                okText="Yes"
                title={
                  <div>
                    Are you sure you want to discard this draft?
                    <br />
                    This cannot be undone.
                  </div>
                }
                onConfirm={() => {
                  dispatch(resetForm({id: `source_manager_${seedId}`}));
                }}
              >
                <Button loading={loadingData} disabled={!isDirty}>
                  Discard Changes
                </Button>
              </Popconfirm>
            </div>

            {formState?.version_history?.length > 1 ? (
              <Collapse
                ghost
                style={{marginTop: '20px'}}
                expandIconPosition="end"
              >
                <Panel header="Version History" key="1">
                  {formState?.version_history?.map((version) => {
                    const _version = {
                      ...version,
                      slug: seedId,
                    };
                    return (
                      <VersionItem
                        currentVersion={version.version}
                        handleDelete={() => deleteSeedVersion(version)}
                        handlePublish={() => {
                          setSeedMetaData({
                            version: version.version,
                            seed_name: seedId,
                            published: !version.published,
                          })
                            .unwrap()
                            .then(() => {
                              message.success({
                                content: version.published
                                  ? 'Unpublished successfully'
                                  : 'Published successfully',
                              });
                            });
                        }}
                        key={version.version}
                        published={version.published}
                        version={_version}
                      />
                    );
                  })}
                </Panel>
              </Collapse>
            ) : null}
          </div>
        </Spin>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        key={seedId + editorMode}
        path={`/source_manager/${seedId}`}
        setDrawerVisible={setDrawerVisible}
      />
    </>
  );
};

export default SeedForm;
