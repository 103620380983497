import {PlusCircleOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Drawer,
  Popconfirm,
  Spin,
  Switch,
  message,
} from 'antd';
import {
  useDeleteReportMutation,
  useFetchAggregateReportsListQuery,
  useFetchReportMetaQuery,
  useFetchReportSourceColumnsQuery,
  useFetchReportSourcesQuery,
  usePublishReportMutation,
  useSaveReportMutation,
} from 'api/reportsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import ColumnForm from 'components/dataReports/ColumnForm';
import EditorButton from 'components/genericComponents/EditorButton';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  resetForm,
  selectFormState,
  selectIsFormEdited,
  setFormData,
  updateFormField,
} from 'store/formSlice';
import styled from 'styled-components';
import {generateUniqueId} from 'utils/helpers';

const {Panel} = Collapse;

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

const DataReportForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reportSlug = location.pathname.split('/')[2];

  const params = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const formState = useSelector((state) =>
    selectFormState(state, `data_report_${reportSlug}`)
  );
  const isDirty = useSelector((state) =>
    selectIsFormEdited(state, `data_report_${reportSlug}`)
  );

  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [allErrors, setAllErrors] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(reportSlug === 'new');
  const [localErrors, setLocalErrors] = useState([]);

  const {data: reports} = useFetchAggregateReportsListQuery(false);
  const {data: reportData, isLoading} = useFetchReportMetaQuery(
    {
      slug: reportSlug,
      version: params.get('version') || 'latest',
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );
  const {data: sources} = useFetchReportSourcesQuery();
  const {data: columns, isLoading: loadingColumns} =
    useFetchReportSourceColumnsQuery(formState?.source, {
      skip: !formState?.source,
    });
  const {data: categories} = useFetchCategoriesQuery();
  const {data: tags} = useFetchTagsQuery();

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createTag] = useCreateOrUpdateTagMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [publishReport] = usePublishReportMutation();
  const [saveReport] = useSaveReportMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    if (reportData) {
      dispatch(
        setFormData({id: `data_report_${reportSlug}`, data: reportData})
      );
    }
  }, [reportData]);

  useEffect(() => {
    if (!formState) return;
    const errors = [];

    if (formState?.columns) {
      formState.columns.forEach((column) => {
        if (!column.alias) {
          errors.push('Column alias is required');
        }
        if (column.column_type === 'METRIC') {
          if (!column.metric_type) {
            errors.push('Metric type is required');
          }
        }
        if (!column.source_column) {
          errors.push('Source column is required');
        }
      });
    } else {
      errors.push('Please add at least one column');
    }
    if (!formState?.report_name) {
      errors.push('Report name is required');
    }
    if (!formState?.source) {
      errors.push('Report source is required');
    }
    if (
      reports?.some(
        (r) =>
          r.report_name?.toLowerCase() ===
            formState.report_name?.toLowerCase() && r.slug !== formState.slug
      )
    ) {
      errors.push('A report with this name already exists');
    }
    setLocalErrors(errors);
  }, [formState]);

  useEffect(() => {
    setAllErrors(localErrors.concat(reportData?.errors || []));
  }, [localErrors, reportData]);

  const handleColumnOrderChanged = (result) => {
    if (!result.destination) return;
    const temp = [...formState?.columns];
    const [reorderedItem] = temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, reorderedItem);
    handleReportDataChanged(temp, 'columns');
  };

  const handleReportDataChanged = (value, type) => {
    dispatch(
      updateFormField({id: `data_report_${reportSlug}`, field: type, value})
    );
    if (type === 'source' && !formState.report_name) {
      dispatch(
        updateFormField({
          id: `data_report_${reportSlug}`,
          field: 'report_name',
          value: value + ' Report',
        })
      );
    }
  };

  const handleAddColumn = (value) => {
    const newId = generateUniqueId(
      formState?.columns.map((column) => column.id)
    );
    handleReportDataChanged(
      [
        ...formState?.columns,
        {
          column_type: value,
          filter_type: 'FREE_TEXT',
          id: newId,
        },
      ],
      'columns'
    );
  };

  const handleColumnChanged = (value, column, field) => {
    const temp = [...formState?.columns];

    const idx = temp?.findIndex((c) => c.id === column.id);
    temp[idx] = updateColumn(value, field, column);

    handleReportDataChanged(temp, 'columns');
  };

  const updateColumn = (value, field, column) => {
    const updatedColumn = {
      ...column,
      [field]: value,
    };

    if (updatedColumn.column_type === 'METRIC') {
      return {
        ...updatedColumn,
        ...updateMetric(field, updatedColumn),
      };
    } else if (field === 'source_column') {
      return {
        ...updatedColumn,
        ...updateColumnSelected(value, updatedColumn),
      };
    }
    return updatedColumn;
  };

  const updateMetric = (field, updatedMetric) => {
    if (field === 'metric_type' || field === 'source_column') {
      updatedMetric.alias = autogenerateMetricColumnName(updatedMetric);
    }
    return updatedMetric;
  };

  const updateColumnSelected = (value, changedElement) => {
    if (!value) return changedElement;

    return {
      ...changedElement,
      source_column: value,
      data_type: columns.find((column) => column.column_name === value)
        ?.data_type,
      alias: value,
    };
  };

  const autogenerateMetricColumnName = (metric) => {
    const c = metric.source_column?.toLowerCase().replace(' ', '_') ?? '';
    const m = metric.metric_type?.toLowerCase().replace(' ', '_') ?? '';
    return `${c}_${m}`;
  };

  const handleSave = () => {
    if (!isDirty) {
      message.info('No changes to save');
    } else if (localErrors.length) {
      message.error('Please fix the errors before saving');
    } else {
      saveReport(formState)
        .unwrap()
        .then((res) => {
          setTimeout(() => {
            // very ugly workaround to make navigate work
            navigate(`/data_report/${res.slug}/editor`);
            message.success('Report saved successfully');
          }, 0);
        });
    }
  };

  const handleDelete = (col) => {
    const temp = [...formState?.columns];
    const idx = temp.findIndex((c) => c.id === col.id);
    temp.splice(idx, 1);
    handleReportDataChanged(temp, 'columns');
  };

  const handleDeleteReport = (version) => {
    // if the report has a published version, it cannot be deleted without specifying a version that is not published
    if (
      (!version && reportData?.has_published_version) ||
      reportData?.published
    ) {
      message.error('Unpublish the report before deleting');
      return;
    }
    if (reportSlug) {
      deleteReport({
        slug: reportSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success('Deleted report');
        });
    }
    navigate(
      version ? `/data_report/${reportSlug}/editor` : `/data_report/editor`
    );
  };

  const handleAddColumnsFromSource = () => {
    const temp = columns.map((column) => ({
      alias: column.column_name,
      column_type: 'DIMENSION',
      data_type: column.data_type,
      filter_type: 'FREE_TEXT',
      id: generateUniqueId(formState?.columns?.map((c) => c.id)),
      source_column: column.column_name,
    }));
    handleReportDataChanged(temp, 'columns');
  };

  return (
    <>
      <Drawer
        closable={true}
        extra={
          <Switch
            checked={reportData?.published}
            checkedChildren="Unpublish"
            disabled={isLoading}
            loading={isLoading}
            onChange={() =>
              publishReport({
                slug: reportSlug,
                version: reportData?.version,
                published: !reportData?.published,
              })
            }
            type={reportData?.published ? 'danger' : 'primary'}
            unCheckedChildren="Publish"
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          position: 'fixed',
          overflowY: 'auto',
          minHeight: 'calc(100% - 64px)',
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        }}
        title="Data Report Editor"
        width={600}
      >
        <Spin spinning={isLoading}>
          <div className="flex-column">
            <div className="flex-row" style={{justifyContent: 'space-between'}}>
              <SelectOptions
                allowClear={false}
                label="Report Source"
                options={sources}
                onChange={(value) => {
                  handleReportDataChanged(value, 'source');
                }}
                required={true}
                rules={[{required: true, message: 'Please select a source'}]}
                style={{flexGrow: 1}}
                value={formState?.source}
              />
              {columns?.length && !formState?.columns?.length && (
                <Button onClick={handleAddColumnsFromSource} size="large">
                  Load All Columns
                </Button>
              )}
            </div>
            <InputField
              label="Name"
              onChange={(e) =>
                handleReportDataChanged(e.target.value, 'report_name')
              }
              required={true}
              // rules={[
              //   {required: true, message: 'Please input a name'},
              //   {
              //     max: 100,
              //     message: 'Name must be less than 100 characters',
              //   },
              //   {
              //     pattern: /^[a-zA-Z0-9_\- ]*$/,
              //   },
              //   {
              //     // unique name validation against all reports
              //     validator: (_, value) => {
              //       if (
              //         reports?.reports?.some(
              //           (r) =>
              //             r.report_name.toLowerCase() === value.toLowerCase()
              //         )
              //       ) {
              //         return Promise.reject(
              //           new Error('A report with this name already exists')
              //         );
              //       }
              //       return Promise.resolve();
              //     },
              //   },
              // ]}
              style={{width: '100%'}}
              value={formState?.report_name}
            />
            {allErrors.length ? (
              <Alert
                type="error"
                description={
                  <div>
                    {allErrors.map((error, idx) => (
                      <div key={idx}>* {error}</div>
                    ))}
                  </div>
                }
              />
            ) : null}
            <SelectOptions
              label="Category"
              onAdd={(name) => {
                createOrUpdateCategory({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleReportDataChanged(res, 'category');
                  });
              }}
              onChange={(value) =>
                handleReportDataChanged(
                  {
                    id: value.id,
                    name: value.title,
                    parent: value.parent,
                  },
                  'category'
                )
              }
              options={categories?.children}
              tree={true}
              value={formState?.category?.id}
            />
            <SelectOptions
              label="Tags"
              onAdd={(name) => {
                createTag({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleReportDataChanged(
                      [...(formState?.tags || []), res],
                      'tags'
                    );
                  });
              }}
              onChange={(value) =>
                handleReportDataChanged(
                  value.map((v) => tags?.find((c) => c.id === v) || null),
                  'tags'
                )
              }
              mode="tags"
              multiple
              options={tags?.map((c) => ({
                color: c.color,
                label: c.name,
                value: c.id,
              }))}
              style={{width: '100%'}}
              value={formState?.tags?.map((t) => t.id)}
            />
            <InputField
              label="Description"
              onChange={(e) =>
                handleReportDataChanged(e.target.value, 'short_description')
              }
              value={formState?.short_description}
              multiline={true}
              style={{width: '100%'}}
            />
            <InputField
              label="Detailed Description"
              onChange={(e) =>
                handleReportDataChanged(e.target.value, 'long_description')
              }
              value={formState?.long_description}
              multiline={true}
              style={{width: '100%'}}
            />
          </div>
          <Divider />

          <Alert
            description={
              <ul>
                <li>
                  Drag and drop columns to change their order in the report.
                </li>
                <li>
                  Frozen columns will always be displayed at the left of the
                  table.
                </li>
                <li>
                  The sort priority follows the order of columns in this list,
                  with the first column having the highest priority. Frozen
                  columns' sort priorities are also determined by their position
                  in this list, even though they’re displayed on the left side
                  of the table.
                </li>
                <li>
                  Filters defined in the <b>Filters</b> section below will be
                  external the table.
                </li>
              </ul>
            }
            type="info"
            style={{marginBottom: '20px'}}
          />
          <Spin spinning={loadingColumns}>
            {columns?.length > 0 && (
              <>
                <div className="flex-row">
                  <div
                    style={{
                      flex: '1 1 300px',
                    }}
                  >
                    <h4>Columns</h4>
                    <DragDropContext onDragEnd={handleColumnOrderChanged}>
                      <Droppable droppableId="1">
                        {(provided) => (
                          <ColumnList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {formState?.columns
                              ?.filter((c) => c.column_type !== 'FILTER')
                              .map((column, idx) => (
                                <ColumnForm
                                  column={column}
                                  columns={columns}
                                  handleDelete={() => handleDelete(column)}
                                  idx={idx}
                                  key={column?.alias}
                                  setColumn={(value, field) =>
                                    handleColumnChanged(value, column, field)
                                  }
                                />
                              ))}
                            {provided.placeholder}
                          </ColumnList>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Button
                      onClick={() => handleAddColumn('DIMENSION')}
                      type="primary"
                    >
                      <PlusCircleOutlined />
                      Add Column
                    </Button>
                  </div>
                </div>
                <Divider />
                <div>
                  <div
                    style={{
                      flex: '1 1 300px',
                    }}
                  >
                    <h4>Filters</h4>

                    <DragDropContext onDragEnd={handleColumnOrderChanged}>
                      <Droppable droppableId="2">
                        {(provided) => (
                          <ColumnList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {formState?.columns
                              ?.filter((c) => c.column_type === 'FILTER')
                              .map((filter, idx) => (
                                <ColumnForm
                                  column={filter}
                                  columns={columns}
                                  handleDelete={() => handleDelete(filter)}
                                  idx={idx}
                                  key={filter?.alias}
                                  setColumn={(value, field) =>
                                    handleColumnChanged(value, filter, field)
                                  }
                                />
                              ))}
                            {provided.placeholder}
                          </ColumnList>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Button
                      onClick={() => handleAddColumn('FILTER')}
                      type="primary"
                    >
                      <PlusCircleOutlined />
                      Add Filter
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Spin>
          <Divider />
          <div className="flex-row" style={{margin: '10px 0'}}>
            <Button disabled={!isDirty} onClick={handleSave} type="primary">
              Save Report
            </Button>
            <Button
              disabled={!isDirty}
              onClick={() =>
                dispatch(resetForm({id: `data_report_${reportSlug}`}))
              }
            >
              Discard Changes
            </Button>
            <Popconfirm
              cancelButtonProps={{type: 'primary'}}
              cancelText="No"
              disabled={reportData?.has_published_version}
              okButtonProps={{danger: true, type: 'default'}}
              okText="Yes"
              onConfirm={() => handleDeleteReport()} // delete report without version
              title={
                <div>
                  Are you sure you want to delete this report?
                  <br />
                  This cannot be undone.
                </div>
              }
            >
              <Button
                danger
                type="primary"
                disabled={reportData?.has_published_version}
              >
                Delete Report
              </Button>
            </Popconfirm>
          </div>
          {formState?.version_history?.length > 1 ? (
            <Collapse
              expandIconPosition="end"
              ghost
              style={{marginTop: '20px'}}
            >
              <Panel header="Version History" key="1">
                {reportData?.version_history?.map((version) => {
                  return (
                    <VersionItem
                      currentVersion={reportData.version}
                      handleDelete={() => handleDeleteReport(version.version)}
                      handlePublish={() => {
                        publishReport({
                          slug: reportSlug,
                          version: version.version,
                          published: !version.published,
                        })
                          .unwrap()
                          .then(() => {
                            message.success({
                              content: version.published
                                ? 'Unpublished successfully'
                                : 'Published successfully',
                            });
                          });
                      }}
                      key={version.version}
                      published={reportData.published}
                      version={version}
                    />
                  );
                })}
              </Panel>
            </Collapse>
          ) : null}
        </Spin>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        key={reportSlug + editorMode}
        path={`/data_report/${reportSlug}`}
        setDrawerVisible={setDrawerVisible}
      />
    </>
  );
};

export default DataReportForm;
