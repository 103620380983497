import Auth from 'Auth';
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Modal,
  Popconfirm,
  Spin,
  Switch,
  message,
} from 'antd';
import {
  useDeletePageMutation,
  useFetchPageQuery,
  usePublishPageMutation,
  useSavePageMutation,
} from 'api/cmsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import WidgetForm from 'components/dataPortal/WidgetForm';
import EditorButton from 'components/genericComponents/EditorButton';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import {isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  resetForm,
  selectFormState,
  selectIsFormEdited,
  setFormData,
  updateFormField,
} from 'store/formSlice';
import {generateUniqueId} from 'utils/helpers';

const {Panel} = Collapse;

const PageForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageSlug = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const formState = useSelector((state) =>
    selectFormState(state, `data_portal_${pageSlug}`)
  );
  const isDirty = useSelector((state) =>
    selectIsFormEdited(state, `data_portal_${pageSlug}`)
  );

  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const [drawerVisible, setDrawerVisible] = useState(pageSlug === 'new');
  const [modalOpen, setModalOpen] = useState(false);
  const [newPageTitle, setNewPageTitle] = useState('');

  const {data: tags} = useFetchTagsQuery();
  const {data: categories} = useFetchCategoriesQuery();
  const {data, isLoading} = useFetchPageQuery(
    {
      slug: pageSlug,
      version: location.search.split('=')[1] || 'latest',
    },
    {skip: !pageSlug || pageSlug === 'new'}
  );

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createTag] = useCreateOrUpdateTagMutation();
  const [deletePage] = useDeletePageMutation();
  const [publishPage] = usePublishPageMutation();
  const [savePage] = useSavePageMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    if (data) {
      dispatch(setFormData({id: `data_portal_${pageSlug}`, data}));
    }
  }, [data]);

  const handleAddWidget = () => {
    const widgets = formState?.widgets || [];
    const newWidget = {
      id: generateUniqueId(widgets, 'int'),
      page: formState?.id,
      position: widgets.length,
    };
    handlePageChanged([...widgets, newWidget], 'widgets');
  };

  const handleDeleteWidget = (widgetId) => {
    const updatedWidgets = formState?.widgets.filter(
      (widget) => widget.id !== widgetId
    );
    handlePageChanged(updatedWidgets, 'widgets');
  };

  const handleWidgetChanged = (value, field, widgetId) => {
    const updatedWidgets = [...formState?.widgets];
    const widgetIndex = updatedWidgets.findIndex((w) => w.id === widgetId);
    updatedWidgets[widgetIndex] = {
      ...updatedWidgets[widgetIndex],
      [field]: value,
    };
    if (field === 'type') {
      updatedWidgets[widgetIndex].extra = {
        owners: true,
        lastModifyingUser: true,
        modifiedTime: true,
      };
    }
    handlePageChanged(updatedWidgets, 'widgets');
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedWidgets = [...formState?.widgets];
    const [removed] = updatedWidgets.splice(result.source.index, 1);
    updatedWidgets.splice(result.destination.index, 0, removed);
    handlePageChanged(updatedWidgets, 'widgets');
  };

  const handlePageChanged = (value, type) => {
    dispatch(
      updateFormField({id: `data_portal_${pageSlug}`, field: type, value})
    );
  };

  const handleSave = () => {
    const page = {
      ...formState,
      // delete local_id from widgets
      widgets: formState?.widgets.map((w) => {
        const temp = {...w};
        delete temp.local_id;
        return temp;
      }),
    };
    if (isEqual(page, data)) {
      message.info('No changes to save');
      return;
    }
    savePage(page)
      .unwrap()
      .then((res) => {
        message.success('Page saved successfully');
        navigate(`/data_portal/${res.slug}/editor`);
      });
  };

  const handleDelete = (version) => {
    if (pageSlug) {
      deletePage({
        slug: pageSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success('Page deleted successfully');
        });
    }
    navigate(
      `/data_report/${pageSlug}/editor`
        ? `/data_portal/${pageSlug}/editor`
        : `/data_portal/editor`
    );
  };

  const handleClone = () => {
    if (!pageSlug) {
      message.error('Cannot clone a new page. Save this page first.');
    }
    const page = {
      category: formState?.category,
      tags: formState?.tags,
      title: newPageTitle,
      widgets: formState?.widgets.map((w) => {
        const temp = {...w};
        delete temp.local_id;
        return temp;
      }),
    };
    savePage(page)
      .unwrap()
      .then((res) => {
        message.success('Page cloned successfully');
        setModalOpen(false);
        navigate(`/data_portal/${res.slug}/editor`);
      });
  };

  return (
    <>
      <Drawer
        closable={true}
        extra={
          <Switch
            checked={data?.published}
            checkedChildren="Unpublish"
            disabled={isLoading}
            loading={isLoading}
            onChange={() => publishPage({...data, published: !data?.published})}
            type={data?.published ? 'danger' : 'primary'}
            unCheckedChildren="Publish"
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          minHeight: 'calc(100% - 64px)',
          overflowY: 'auto',
          position: 'fixed',
        }}
        title="Page Editor"
        width={600}
      >
        <Spin spinning={isLoading}>
          <div className="flex-column">
            <InputField
              label="Title"
              onChange={(e) => handlePageChanged(e.target.value, 'title')}
              required={true}
              style={{width: '300px'}}
              value={formState?.title}
            />
            <SelectOptions
              label="Category"
              onAdd={(name) => {
                createOrUpdateCategory({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handlePageChanged(res, 'category');
                  });
              }}
              onChange={(value) =>
                handlePageChanged(
                  {
                    id: value.id,
                    name: value.title,
                    parent: value.parent,
                  },
                  'category'
                )
              }
              options={categories?.children}
              tree={true}
              value={formState?.category?.id}
            />
            <SelectOptions
              label="Tags"
              onAdd={(name) => {
                createTag({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handlePageChanged(
                      [...(formState?.tags || []), res],
                      'tags'
                    );
                  });
              }}
              onChange={(value) =>
                handlePageChanged(
                  value.map((v) => tags?.find((c) => c.id === v) || null),
                  'tags'
                )
              }
              options={tags?.map((c) => ({
                color: c.color,
                label: c.name,
                value: c.id,
              }))}
              mode="tags"
              multiple
              style={{width: '100%'}}
              value={formState?.tags?.map((t) => t.id)}
            />
            {Auth.permissions.access_to_search && (
              <div>
                <Checkbox
                  checked={formState?.is_search_context}
                  onChange={(e) =>
                    handlePageChanged(e.target.checked, 'is_search_context')
                  }
                >
                  Include in search context
                </Checkbox>
                <Checkbox
                  checked={formState?.is_spotlight}
                  onChange={(e) =>
                    handlePageChanged(e.target.checked, 'is_spotlight')
                  }
                >
                  Set as Editor's Pick
                </Checkbox>
              </div>
            )}
          </div>
          <Divider />
          <Alert
            description={
              <div>
                <h4>Superset:</h4>
                <ol>
                  <li>
                    <b>Dashboard ID</b>: copy the number after{' '}
                    <b>'/dashboard/'</b> in the URL of your dashboard.
                  </li>
                  <li>
                    <b>Embed ID</b>: click <b>'Embed dashboard'</b> and copy the
                    ID.
                  </li>
                  <li>
                    Paste both IDs into the corresponding fields in the SuperSet
                    Dashboard's widget.
                  </li>
                </ol>
                <h4>Sharepoint (PDF):</h4>
                <ol>
                  <li>
                    In the sharepoint document, click on <b>'...'</b> and then
                    on <b>'Embed'</b>.
                  </li>
                  <li>
                    Copy the URL from the <b>'src'</b> attribute in the iframe
                    and paste to the <b>Source</b> field.
                  </li>
                  <li>
                    Optionally, add a title to the <b>Title</b> field.
                  </li>
                </ol>
                <h4>Google:</h4>
                <ol>
                  <li>
                    At the top right corner, click <b>'Share'</b>. Ensure{' '}
                    <b>General access</b> is set to either:
                    <ul>
                      <li>Anyone with the link</li>
                      <li>Uncommon Schools</li>
                    </ul>
                  </li>
                  <li>
                    Copy the Google file's URL and paste the URL into the Google
                    File's widget.
                  </li>
                </ol>
              </div>
            }
            type="success"
            style={{marginBottom: '20px'}}
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="widgets">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex-column"
                >
                  {formState?.widgets?.map((widget) => (
                    <WidgetForm
                      key={widget.id}
                      widget={widget}
                      setWidget={(value, field) =>
                        handleWidgetChanged(value, field, widget.id)
                      }
                      deleteWidget={handleDeleteWidget}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div>
            <Button
              onClick={handleAddWidget}
              style={{marginTop: '10px'}}
              type="primary"
            >
              Add Widget
            </Button>
          </div>
          <Divider />
          <div className="flex-row">
            <Button type="primary" onClick={handleSave} disabled={!isDirty}>
              Save Page
            </Button>
            <Button onClick={() => setModalOpen(true)}>Duplicate Page</Button>
            <Button
              onClick={() =>
                dispatch(resetForm({id: `data_portal_${pageSlug}`}))
              }
            >
              Discard Changes
            </Button>
            {pageSlug && (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onConfirm={() => handleDelete()}
                title="Are you sure you want to delete this page?"
              >
                <Button type="danger">Delete Page</Button>
              </Popconfirm>
            )}
          </div>
          {formState?.version_history?.length > 1 ? (
            <Collapse
              expandIconPosition="end"
              ghost
              style={{marginTop: '20px'}}
            >
              <Panel header="Version History" key="1">
                {formState?.version_history?.map((version) => {
                  return (
                    <VersionItem
                      currentVersion={formState.version}
                      handleDelete={() => handleDelete(version.version)}
                      handlePublish={() => {
                        publishPage({
                          slug: pageSlug,
                          version: version.version,
                          published: !version.published,
                        })
                          .unwrap()
                          .then(() => {
                            message.success(
                              version.published
                                ? 'Unpublished successfully'
                                : 'Published successfully'
                            );
                          });
                      }}
                      key={version.version}
                      publishedVersion={formState.published}
                      version={version}
                    />
                  );
                })}
              </Panel>
            </Collapse>
          ) : null}
          <Modal
            onCancel={() => setModalOpen(false)}
            onOk={handleClone}
            open={modalOpen}
            title="Duplicate Page"
          >
            <InputField
              label="New Page Title"
              onChange={(e) => setNewPageTitle(e.target.value)}
              required={true}
              value={newPageTitle}
            />
          </Modal>
        </Spin>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        key={pageSlug + editorMode}
        path={`/data_portal/${pageSlug}`}
        setDrawerVisible={setDrawerVisible}
      />
    </>
  );
};

export default PageForm;
