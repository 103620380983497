import {PlusOutlined} from '@ant-design/icons';
import {Input, Tag, Tooltip} from 'antd';
import React, {useEffect, useRef, useState} from 'react';

const Tags = ({tags, setTags, style = {}}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e) => {
    setNewTags(inputValue);
    setInputValue('');
    inputRef.current?.blur();
    setInputVisible(false);
  };

  const setNewTags = (value) => {
    const newTagsArr = value.split(';');
    const allTagsArr = [...new Set([...tags, ...newTagsArr])].sort();
    setTags(allTagsArr);
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    setNewTags(editInputValue);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  return (
    <Tooltip title="Tags are separated by semicolons">
      <div
        className="flex-row"
        style={{
          border: '1px solid #d9d9d9',
          padding: '2px',
          minHeight: '32px',
          minWidth: '100px',
          ...style,
        }}
        onClick={showInput}
      >
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={tag}
                size="small"
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={(e) => handleEditInputConfirm(e)}
                onPressEnter={(e) => handleEditInputConfirm(e)}
              />
            );
          }
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable={true}
              style={{marginRight: 0}}
              onClose={() => handleClose(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    setEditInputIndex(index);
                    setEditInputValue(tag);
                    e.preventDefault();
                  }
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={handleInputChange}
            onPressEnter={(e) => handleInputConfirm(e)}
            onBlur={(e) => handleInputConfirm(e)}
          />
        ) : (
          <Tag
            className="site-tag-plus"
            onClick={showInput}
            color="var(--primary-color)"
          >
            <PlusOutlined /> New
          </Tag>
        )}
      </div>
    </Tooltip>
  );
};

export default Tags;
