import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {Button, Form, Popconfirm, Segmented, Tag, Tooltip, Tree} from 'antd';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useDeleteCategoryMutation,
  useDeleteTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import ColorPicker from 'components/genericComponents/ColorPicker';
import InputField from 'components/genericComponents/Input';
import {createNestedDataFilter} from 'hooks/NestedFilter';
import React, {useEffect, useState} from 'react';
import {getWeightedWidth, setContrast} from 'utils/helpers';

const TagForm = ({tag = {}, tags}) => {
  const [form] = Form.useForm();

  const [createOrUpdateTag] = useCreateOrUpdateTagMutation();

  const [inputWidth, setInputWidth] = useState(
    () => getWeightedWidth(tag.name || '', 275) + 25
  );

  const textColor = setContrast(tag?.color);

  const onFinish = (values) => {
    if (tag.name === values.name && tag.color === values.color) return;
    createOrUpdateTag({
      ...tag,
      name: values.name,
      color: values.color,
    });
    if (!tag.id) {
      form.resetFields();
    } else {
      // convoluted way to reset the form without losing the new values
      form.resetFields();
      form.setFieldValue('name', values.name);
      form.setFieldValue('color', values.color);
    }
  };

  const formStyle = tag.id
    ? {}
    : {
        border: '#d9d9d9 solid 1px',
        borderRadius: 2,
        margin: 0,
        padding: '0 0 0 16px',
        width: 'min-content',
      };

  return (
    <Form
      form={form}
      initialValues={tag}
      onFinish={onFinish}
      validateTrigger="onSubmit"
      style={{
        alignItems: 'start',
        display: 'inline-flex',
        ...formStyle,
      }}
    >
      <Form.Item name="color" style={{lineHeight: 0, margin: 0}}>
        <ColorPicker initialValue={tag.color} />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[
          {required: true, message: 'Tag name is required'},
          {
            max: 50,
            message: 'Tag name must be less than 50 characters',
          },
          () => ({
            validator(_, value) {
              if (
                tags.some(
                  (t) =>
                    t.name?.toLowerCase() === value?.toLowerCase() &&
                    t.id !== tag.id
                )
              ) {
                return Promise.reject('Tag name already exists');
              }
              return Promise.resolve();
            },
          }),
        ]}
        style={{marginBottom: 0}}
      >
        <InputField
          allowClear={!tag.id}
          bordered={false}
          placeholder="New Tag Name"
          size="medium"
          style={{
            color: textColor,
            padding: 0,
            minWidth: '50px',
            width: `${inputWidth}px`,
          }}
          onChange={(e) => {
            if (!tag.id) return;
            const newWidth = getWeightedWidth(e.target.value, 275) + 25;
            setInputWidth(newWidth);
          }}
        />
      </Form.Item>
      {tag.id ? (
        <Form.Item shouldUpdate noStyle>
          {() => {
            const isModified = form.isFieldsTouched();
            return isModified ? (
              <>
                <Button type="text" htmlType="submit">
                  <CheckOutlined />
                </Button>
                <Button onClick={() => form.resetFields()} type="text">
                  <CloseOutlined />
                </Button>
              </>
            ) : null;
          }}
        </Form.Item>
      ) : (
        <Form.Item shouldUpdate noStyle>
          {() => {
            const isModified = form.isFieldsTouched();
            return (
              <>
                <Button
                  htmlType="submit"
                  style={{marginLeft: 8}}
                  type="primary"
                >
                  New Tag
                </Button>
                {isModified && (
                  <Button onClick={() => form.resetFields()} type="default">
                    Discard
                  </Button>
                )}
              </>
            );
          }}
        </Form.Item>
      )}
    </Form>
  );
};

const Tags = () => {
  const [filterTag, setFilterTag] = useState('all');
  const [displayedTags, setDisplayedTags] = useState([]);
  const [options, setOptions] = useState([]);

  const {data: tags} = useFetchTagsQuery();
  const [deleteTag] = useDeleteTagMutation();

  const alphabetOptions = Array.from({length: 26}, (_, i) => {
    const char = String.fromCharCode(i + 65);
    return {label: char, value: char, disabled: true};
  });

  const updateOptions = (tags) => {
    setOptions([
      {label: 'All', value: 'all'},
      {label: '#', value: '#'},
      ...alphabetOptions.map((option) => ({
        ...option,
        disabled: !tags?.some(
          (tag) =>
            tag.name?.startsWith(option.label) ||
            tag.name?.startsWith(option.label.toLowerCase())
        ),
      })),
    ]);
  };

  useEffect(() => {
    updateOptions(tags);
  }, [tags]);

  useEffect(() => {
    if (filterTag === 'all') {
      setDisplayedTags(tags);
    } else if (filterTag === '#') {
      // Display only tags that start with numbers or symbols
      setDisplayedTags(tags.filter((tag) => !/[a-zA-Z]/.test(tag.name[0])));
    } else {
      const filteredTags = tags.filter(
        (tag) =>
          tag.name?.startsWith(filterTag) ||
          tag.name?.startsWith(filterTag.toLowerCase())
      );
      setDisplayedTags(filteredTags);
    }
  }, [filterTag, tags]);

  return (
    <div style={{flexGrow: 1, width: '500px'}}>
      <h2 style={{margin: 5}}>Tags</h2>
      <div className="flex-column">
        <TagForm tags={tags} />
        <Segmented
          onChange={(value) => setFilterTag(value)}
          options={options}
          size="small"
          style={{width: 'min-content'}}
          value={filterTag}
        />
        <div style={{display: 'flex', flexWrap: 'wrap', gap: 10}}>
          {displayedTags?.map((tag) => {
            const textColor = setContrast(tag?.color);

            return (
              <Tag
                color={tag?.color}
                key={tag.id}
                style={{
                  alignItems: 'center',
                  borderColor:
                    textColor === '#000000d9' ? '#d9d9d9' : tag.color,
                  color: textColor,
                  display: 'flex',
                  margin: 0,
                  padding: '0 16px',
                }}
              >
                <TagForm tag={tag} tags={tags} />
                <Tooltip title="Delete Tag">
                  <Popconfirm
                    cancelText="No"
                    okText="Yes"
                    onConfirm={() => deleteTag(tag?.id)}
                    title="Are you sure you want to delete this tag?"
                  >
                    <CloseCircleOutlined />
                  </Popconfirm>
                </Tooltip>
              </Tag>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const useFilter = (data) => {
  const {flattenTree} = createNestedDataFilter();

  const flattenedTree = flattenTree(data);

  return {flattenedTree};
};

const CategoryForm = ({category = {}, initialCategory = {}}) => {
  const [form] = Form.useForm();

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();

  const [inputWidth, setInputWidth] = useState(
    () => getWeightedWidth(initialCategory.title || '', 275) + 25
  );

  const onFinish = (values) => {
    if (initialCategory.name === values.title) return;
    createOrUpdateCategory({
      ...category,
      parent: initialCategory.parent,
      name: values.title,
    });
    if (!category.id) {
      form.resetFields();
    } else {
      // convoluted way to reset the form without losing the title
      form.resetFields();
      form.setFieldValue('title', values.title);
    }
  };

  const formStyle = category.id
    ? {}
    : {
        border: '#d9d9d9 solid 1px',
        borderRadius: 2,
        margin: 0,
        padding: '0 0 0 16px',
        width: 'min-content',
      };

  return (
    <Form
      form={form}
      initialValues={category}
      onFinish={onFinish}
      validateTrigger="onSubmit"
      style={{
        alignItems: 'start',
        display: 'inline-flex',
        ...formStyle,
      }}
    >
      <Form.Item
        name="title"
        rules={[
          {required: true, message: 'Category name is required'},
          {
            max: 50,
            message: 'Category name must be less than 50 characters',
          },
        ]}
        style={{marginBottom: 0}}
      >
        <InputField
          allowClear={!category.id}
          bordered={false}
          placeholder="New Category Name"
          size="medium"
          style={{
            backgroundColor: 'transparent',
            padding: 0,
            maxWidth: '400px',
            minWidth: '50px',
            width: `${inputWidth}px`,
          }}
          onChange={(e) => {
            if (!category.id) return;
            const newWidth = getWeightedWidth(e.target.value, 275) + 25;
            setInputWidth(newWidth);
          }}
        />
      </Form.Item>
      {category.id ? (
        <Form.Item shouldUpdate noStyle>
          {() => {
            const isModified = form.isFieldsTouched();
            return isModified ? (
              <>
                <Button type="text" htmlType="submit">
                  <CheckOutlined />
                </Button>
                <Button onClick={() => form.resetFields()} type="text">
                  <CloseOutlined />
                </Button>
              </>
            ) : null;
          }}
        </Form.Item>
      ) : (
        <Form.Item shouldUpdate noStyle>
          {() => {
            const isModified = form.isFieldsTouched();
            return (
              <>
                <Button
                  htmlType="submit"
                  style={{marginLeft: 8}}
                  type="primary"
                >
                  New Category
                </Button>
                {isModified && (
                  <Button onClick={() => form.resetFields()} type="default">
                    <CloseOutlined />
                  </Button>
                )}
              </>
            );
          }}
        </Form.Item>
      )}
    </Form>
  );
};

const Categories = () => {
  const [defaultData, setDefaultData] = useState([]);

  const {data: categories} = useFetchCategoriesQuery();
  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  const {flattenedTree} = useFilter(categories?.children);

  useEffect(() => {
    if (!categories) return;

    const loop = (data) => {
      const looped = data?.map((category) => {
        return {
          ...category,
          children: category.children ? loop(category.children) : [],
          key: category.id,
          title: (
            <>
              <Tooltip title="Delete Category">
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => deleteCategory(category?.id)}
                  title="Are you sure you want to delete this category?"
                >
                  <CloseCircleOutlined />
                </Popconfirm>
              </Tooltip>
              <CategoryForm
                category={category}
                initialCategory={flattenedTree.find(
                  (c) => c.id === category.id
                )}
              />
            </>
          ),
        };
      });
      return looped;
    };

    setDefaultData(loop(categories?.children));
  }, [categories]);

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dragPos = info.dragNode.pos.split('-');
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const draggedCategory = flattenedTree.find(
      (category) => category.id === dragKey
    );

    // If the node is dropped in the same directory, do nothing
    if (dragPos.join('-') === dropPos.slice(0, -1).join('-')) {
      return;
    }

    const loop = (data, key, callback) => {
      for (let item of data) {
        if (item.key === key) return callback(item, data);
        if (item.children) loop(item.children, key, callback);
      }
    };

    const newData = [...defaultData];
    let dragObj;

    loop(newData, dragKey, (item, arr) => {
      arr.splice(arr.indexOf(item), 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(newData, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      loop(newData, dropKey, (item, arr) => {
        ar = arr;
        ar.splice(
          dropPosition === -1 ? ar.indexOf(item) : ar.indexOf(item) + 1,
          0,
          dragObj
        );
      });
    }

    createOrUpdateCategory({
      id: dragKey,
      name: draggedCategory.title,
      parent: info.dropToGap ? info.node.parentId : dropKey,
    });

    setDefaultData(newData);
  };

  return (
    <div style={{width: '500px'}}>
      <h2 style={{margin: 5}}>Categories</h2>
      <div className="flex-column">
        <CategoryForm />
        {defaultData?.length ? (
          <Tree
            autoExpandParent
            defaultExpandAll
            draggable
            icon={() => null}
            onDrop={onDrop}
            selectable={false}
            showIcon
            treeData={defaultData}
          />
        ) : null}
      </div>
    </div>
  );
};

const TagsAndCategoriesPage = () => {
  return (
    <div className="flex-row">
      <Categories />
      <Tags />
    </div>
  );
};

export default TagsAndCategoriesPage;
