import {createSelector, createSlice} from '@reduxjs/toolkit';
import {generateUniqueId} from 'utils/helpers';

const selectChatState = (state) => state.chat;

const defaultChatState = {
  artifacts: [],
  infoItem: null,
  messages: [],
  session: null,
  suggestions: [],
};

export const makeSelectChatData = () => {
  return createSelector(
    [selectChatState, (_, tokenId) => tokenId],
    (chatState, tokenId) => {
      if (!tokenId) return defaultChatState;
      return chatState[tokenId] || defaultChatState;
    }
  );
};

export const chatSlice = createSlice({
  initialState: {},
  name: 'chat',
  reducers: {
    addMessage(state, action) {
      const {id, data} = action.payload;
      const newMessage = {
        id: generateUniqueId(state[id].messages.map((m) => m.id)),
        time: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
        author: data.author,
        content: data.content,
      };
      state[id].messages.push(newMessage);
    },
    setInfoItem(state, action) {
      const {id, data} = action.payload;
      state[id].infoItem = data;
    },
    createChat(state, action) {
      const {id, session} = action.payload;
      state[id] = {
        messages: [],
        session,
        artifacts: [],
        suggestions: [
          'What is the purpose of the data portal?',
          'What data is available on the data portal?',
          'How can I access the data portal?',
        ],
      };
    },
    addArtifacts(state, action) {
      const {id, data} = action.payload;
      // only add artifacts if they are not already in the list
      if (!state[id].artifacts.find((artifact) => artifact.id === data.id)) {
        state[id].artifacts.push(data);
      }
    },
    setSuggestions(state, action) {
      const {id, data} = action.payload;
      state[id].suggestions = data;
    },
  },
});

export const {
  addMessage,
  setInfoItem,
  createChat,
  addArtifacts,
  setSuggestions,
} = chatSlice.actions;

export default chatSlice.reducer;
