import {embedDashboard} from '@superset-ui/embedded-sdk';
import {useFetchSupersetTokenQuery} from 'api/cmsSlice';
import React, {useEffect} from 'react';
import {SUPERSET_URL} from 'utils/consts';

const TOKEN_REFRESH_INTERVAL = 280000;

const SupersetChartDisplay = ({source, dashboardId}) => {
  const {data: guestToken, refetch} = useFetchSupersetTokenQuery(dashboardId, {
    skip: !dashboardId,
  });

  const dashboardUiConfig = {
    hideTitle: true,
    hideTab: false,
    hideChartControls: false,
    filters: {
      visible: false,
      expanded: false,
    },
  };

  useEffect(() => {
    if (!source || !dashboardId || !guestToken) return;
    const container = document.getElementById(`dashboard_${dashboardId}`);
    const embed = async () => {
      try {
        await embedDashboard({
          dashboardUiConfig,
          fetchGuestToken: () => {
            return guestToken;
          },
          id: source, // given by the Superset embedding UI
          mountPoint: container, // html element in which iframe render
          supersetDomain: SUPERSET_URL,
        });
      } catch (err) {
        console.error(err);
      }
    };
    if (container) {
      embed();
      if (container && container.children[0]) {
        container.style.width = '100%';
        const iframe = container.children[0];
        iframe.style.height = '800px';
        iframe.style.width = '100%';
        iframe.style.border = 'none';
      }
    }
  }, [source, dashboardId, guestToken]);

  // refresh token every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, TOKEN_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [dashboardId]);

  return (
    <>
      {source && dashboardId && guestToken && (
        <div key={guestToken} id={`dashboard_${dashboardId}`} />
      )}
    </>
  );
};

export default SupersetChartDisplay;
